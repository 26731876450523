import * as React from "react";

import { BuyModel, } from "model";
import { SessionContext, TSessionContext } from "lib";
import { BuyProvider } from "pages/buy/buy_context";
import { EditBuyModal } from "../input";

interface Props {
  buy: BuyModel;
  open: boolean;
  onClose: () => void;
}

export const EditBuy: React.FC<Props> = ({ buy, open, onClose }: Props) => {
  const { currentUnit, currentUser, isOwner } = React.useContext(SessionContext) as TSessionContext;

  const closeEdit = () => {
    if (onClose) {
      onClose();
    }
  }
  if (isOwner() && currentUser && currentUnit) {
    return (
      <BuyProvider creator={currentUser} buy={buy} unit={currentUnit} >
        <EditBuyModal
          open={open}
          onClose={closeEdit}
        />
      </BuyProvider>
    );
  } else {
    return (<></>);
  }
};