import * as React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

import { InfoBar, MonthSelector, NotFound, PublicFooter } from 'components';
import { api, isEmail, isPhoneNumber } from 'lib';

import { useGetRewardsSignupForm } from './api';
import { CustomerInput, initialCustomer, useSignup } from './api/signup';
import { RewardsSignupFormModel, UnitModel } from 'model';

interface UnitsListProps {
  units: UnitModel[];
}

const UnitsList: React.FC<UnitsListProps> = ({ units }: UnitsListProps) => {
  return (
    <Grid item xs={12} md={12} style={{ marginTop: 36 }}>
      <h4>Rewards valid at the following locations:</h4>
      {units?.map((unit) =>
        <Grid item key={unit.id} xs={12} md="auto">
          <strong>Bricks & Minifigs {unit.name}</strong><br />
          {unit.address}&nbsp;{unit.address2}<br />
          {unit.city}, {unit.state}&nbsp;{unit.postal_code}
        </Grid>
      )}
    </Grid>
  );
};


interface FormProps {
  form: RewardsSignupFormModel;
  onSubmit?: (status: string) => void;
}

const RewardsSignupForm: React.FC<FormProps> = ({ form, onSubmit }: FormProps) => {
  const { signup, status: signupStatus, error: signupError } = useSignup();
  const [customer, setCustomer] = React.useState<CustomerInput>(initialCustomer);
  const updateCustomer = (prop: string, value: string | boolean) => setCustomer({ ...customer, [prop]: value })

  const isEmailValid = React.useMemo(() => {
    return customer.email.length === 0 || (customer.email.length > 0 && isEmail(customer.email));
  }, [customer.email]);

  const isPhoneValid = React.useMemo(() => {
    return customer.phone.length === 0 || (customer.phone.length > 0 && isPhoneNumber(customer.phone));
  }, [customer.phone]);

  const isFormValid = React.useMemo(() => {
    if (!isEmail(customer.email) || customer.first_name.length < 1 || customer.last_name.length < 1 || !isPhoneNumber(customer.phone)) {
      return false;
    }
    return true;
  }, [customer]);

  const doSignup = () => {
    if (form) {
      signup(customer, form.short_code);
    }
  };

  React.useEffect(() => {
    if (onSubmit && signupStatus === api.success) {
      onSubmit(signupStatus);
    }
  }, [signupStatus, onSubmit]);

  if (signupStatus === api.loading) {
    return (
      <LinearProgress />
    );
  } else {
    return (
      <>
        <Grid item xs={12}>
          <h2>{form.header}</h2>
        </Grid>
        <Grid item xs={12}>
          <p style={{ fontSize: 20, width: "80%", margin: "16px auto" }}>
            {form.message}
          </p>
        </Grid>
        {signupStatus === api.error &&
          <Grid item xs={12}>
            <InfoBar status="error" message={signupError} />
          </Grid>
        }
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="first_name"
            label="First Name"
            variant="outlined"
            fullWidth
            value={customer ? customer.first_name : ""}
            onChange={(e) => updateCustomer('first_name', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="last_name"
            label="Last Name"
            variant="outlined"
            fullWidth
            value={customer ? customer.last_name : ""}
            onChange={(e) => updateCustomer('last_name', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="email"
            label="Email"
            variant="outlined"
            fullWidth
            value={customer ? customer.email : ""}
            onChange={(e) => updateCustomer('email', e.target.value)}
            error={!isEmailValid}
            helperText={!isEmailValid ? "A valid email is required" : ""}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="phone"
            label="Phone"
            variant="outlined"
            fullWidth
            value={customer ? customer.phone : ""}
            onChange={(e) => updateCustomer('phone', e.target.value)}
            error={!isPhoneValid}
            helperText={!isPhoneValid ? "A valid phone is required, 800-555-1212" : ""}
          />
        </Grid>
        {
          form.birth_month &&
          <Grid item xs={12} md={12} textAlign="left">
            <MonthSelector
              label="Birthday Month"
              onSelect={(value) => updateCustomer('birth_month', value)}
              helperText="Optional: Receive a free gift on your Birthday Month!"
            />
          </Grid>
        }
        {
          form.source && form.source_customfield_options &&
          <Grid item xs={12} md={12} textAlign="left">
            <FormControl fullWidth>
              <InputLabel id="source-select-label">How did you hear about us?</InputLabel>
              <Select
                labelId="source-select-label"
                id="source"
                onChange={(e: SelectChangeEvent) => updateCustomer('source', e.target.value)}
                label="How did you hear about us?"
              >
                {form.source_customfield_options.map((m) =>
                  <MenuItem key={m} value={m}>{m}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        }
        {
          form.email_marketing &&
          <Grid item xs={12} md={12} textAlign="left">
            <FormControlLabel
              value="1"
              control={<Checkbox onChange={(e) => updateCustomer('email_marketing', e.target.checked)} />}
              label="I would like to receive updates and news via email"
            />
          </Grid>
        }
        <Grid item xs={12} md={12}>
          <Button
            variant="contained"
            aria-label="Sign Up for FREE today!"
            color="secondary"
            disabled={!isFormValid}
            onClick={doSignup}
          >
            Sign up for FREE today!
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          {form.units && <UnitsList units={form.units} />}
        </Grid>
      </>
    );
  }
};

interface ConfirmationProps {
  confirmationMessage: string;
  units?: UnitModel[];
};

export const RedirectToStoreSignup = () => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const slug = queryParams.get('slug');
    if (slug) {
      navigate(`/rewards/${slug}`, { replace: true });
    }
  }, [location, navigate]);

  return null;
}

export const RewardsSignupConfirmation: React.FC<ConfirmationProps> = ({ confirmationMessage, units }: ConfirmationProps) => {
  return (
    <>
      <Grid item xs={12}>
        <h2> Thank you for joining our rewards program!</h2>
      </Grid>
      <Grid item xs={12}>
        {confirmationMessage}
      </Grid>
      <Grid item xs={12} md={12}>
        {units && <UnitsList units={units} />}
      </Grid>
    </>
  );
};

interface Status {
  state: string;
  status: string;
  message: string;
};
export const RewardsSignup: React.FC = () => {
  const { id } = useParams();
  const { get, status: loadStatus, error: loadError, rewardsSignupForm } = useGetRewardsSignupForm();
  const [status, setStatus] = React.useState<Status>({ state: 'init', status: api.idle, message: '' });

  React.useEffect(() => {
    if (id) {
      get(id);
    }
  }, [id, get]);

  React.useEffect(() => {
    if (loadStatus !== api.idle) {
      setStatus({ state: 'form', status: loadStatus, message: loadError });
    }
  }, [loadStatus, loadError]);

  const onSubmit = (status: string) => {
    setStatus({ state: 'submit', status: status, message: '' });
  };


  if (status.status === api.loading) {
    return (<div style={{ marginTop: 32, width: 800 }}><LinearProgress /></div>);
  } else if (status.status === api.error) {
    return (<NotFound />);
  } else if (status.status === api.success && rewardsSignupForm) {
    return (
      <>
        <div className="content" style={{ maxWidth: 800, margin: '0 auto', paddingBottom: 32 }}>
          <Grid container spacing={2} textAlign="center">
            <Grid item xs={12}>
              <img src="/logo_straight.png" width={300} alt="Bricks & Minifigs" />
            </Grid>
            {status.state === 'form' && <RewardsSignupForm form={rewardsSignupForm} onSubmit={onSubmit} />}
            {status.state === 'submit' && <RewardsSignupConfirmation confirmationMessage={rewardsSignupForm.confirmation_message} units={rewardsSignupForm.units} />}
          </Grid>
        </div>
        <PublicFooter />
      </>
    );
  } else {
    return (<></>);
  }
};