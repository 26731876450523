import * as React from 'react'
import { Box, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatCurrency } from 'lib';
import { BuyModel, AnimalBuyLineModel, AnimalModel } from 'model';
import { CardModal, AnimalCard } from 'components';

interface Props {
  buy: BuyModel;
}

export const AnimalSection: React.FC<Props> = ({ buy }: Props) => {
  const [selectedAnimal, setSelectedAnimal] = React.useState<AnimalModel | null>(null);
  const handleCloseAnimalCard = () => setSelectedAnimal(null);
  let total = 0;

  const getAnimalRows: AnimalBuyLineModel[] = React.useMemo(() => {
    return buy.animal_buy_lines.filter((row) => !row.deleted);
  }, [buy.animal_buy_lines]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Box sx={{ p: 2, fontWeight: 700 }}>Animals</Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="Animalures">
        <TableHead>
          <TableRow>
            <TableCell width={"40%"}>Description</TableCell>
            <TableCell align="left">Category</TableCell>
            <TableCell align="left">Condition</TableCell>
            <TableCell width={50} align="right">Qty</TableCell>
            <TableCell width={75} align="right">Cost</TableCell>
            <TableCell width={75} align="right">Value</TableCell>
            <TableCell width={75} align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getAnimalRows.map((row) => {
            const adjValue = row.value - row.value_discount;
            total += row.cost_final * row.quantity;
            return (
              <TableRow key={row.id}>
                <TableCell>
                  {row.animal ? <Link onClick={() => setSelectedAnimal(row.animal || null)}>{row.description}</Link> : row.description}
                  <br />
                  {row.notes}
                </TableCell>
                <TableCell>{row.animal ? row.animal.category : "Animal"}</TableCell>
                <TableCell align="left">{row.condition}</TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
                <TableCell align="right">{formatCurrency(row.cost_final)}</TableCell>
                <TableCell align="right">{formatCurrency(adjValue)}</TableCell>
                <TableCell align="right">{formatCurrency(row.quantity * adjValue)}</TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell colSpan={2} />
            <TableCell align="right"></TableCell>
            <TableCell align="right">{buy.total_animal_qty + buy.total_cmf_qty}</TableCell>
            <TableCell align="right">{formatCurrency(total)}</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">{formatCurrency(buy.total_animal_value + buy.total_cmf_value)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardModal open={selectedAnimal !== null} onClose={handleCloseAnimalCard}>
        <AnimalCard animal={selectedAnimal} />
      </CardModal>
    </TableContainer >
  );
};