import * as React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import {
  AnimalBuyLineModel,
  BulkBuyLineModel,
  BuyModel,
  BuySettingModel,
  CustomerImportModel,
  MinifigBuyLineModel,
  MiscBuyLineModel,
  NewSetBuyLineModel,
  SeriesMinifigBuyLineModel,
  UnitModel,
  UsedSetBuyLineModel,
  UserModel,
} from 'model';
import { useLoadBuySettings, useSaveBuy } from 'api';
import { api } from 'lib';
import { useBuyWarnings } from 'lib/hooks';

export type TBuyContext = {
  buy: BuyModel;
  addMinifig: (line: MinifigBuyLineModel) => void;
  deleteMinifig: (key: number) => void;
  updateMinifig: (line: MinifigBuyLineModel) => void;
  addCMF: (line: SeriesMinifigBuyLineModel) => void;
  deleteCMF: (key: number) => void;
  updateCMF: (line: SeriesMinifigBuyLineModel) => void;
  addAnimal: (line: AnimalBuyLineModel) => void;
  deleteAnimal: (key: number) => void;
  updateAnimal: (line: AnimalBuyLineModel) => void;
  addNewSet: (line: NewSetBuyLineModel) => void;
  deleteNewSet: (key: number) => void;
  updateNewSet: (line: NewSetBuyLineModel) => void;
  addUsedSet: (line: UsedSetBuyLineModel) => void;
  deleteUsedSet: (key: number) => void;
  updateUsedSet: (line: UsedSetBuyLineModel) => void;
  addBulk: (line: BulkBuyLineModel) => void;
  deleteBulk: (key: number) => void;
  updateBulk: (line: BulkBuyLineModel) => void;
  addMisc: (line: MiscBuyLineModel) => void;
  deleteMisc: (key: number) => void;
  updateMisc: (line: MiscBuyLineModel) => void;
  updateAsking: (value: number) => void;
  updateCashType: (value: string, reference: string) => void;
  updateOfferValue: (type: string, value: number) => void;
  updatePaidValue: (type: string, value: number) => void;
  updateBuyType: (type: string) => void;
  updateSummary: (summary: string) => void;
  updateSignature: (key: string, value: string) => void;
  setCustomer: (customer: CustomerImportModel) => void;
  setCreator: (user: UserModel) => void;
  setUnit: (unit: UnitModel) => void;
  save: () => void;
  finalize: () => void;
  cancel: (note: string) => void;
  revert: () => void;
  complete: () => void;
  setBuy: (buy: BuyModel) => void;
  warnings: string[];
  saveStatus: string;
  saveError: string;
};

interface BuyContextProps {
  unit: UnitModel;
  creator: UserModel;
  children: React.ReactNode;
  buy?: BuyModel;
  onSave?: (success: boolean, error: string) => void;
}

export const BuyContext = React.createContext<TBuyContext | null>(null);

const newBuy = (unit: UnitModel, creator: UserModel): BuyModel => ({
  id: 0,
  creator: creator,
  unit: unit,
  asking: 0,
  credit_min: 0,
  credit_max: 0,
  cash_min: 0,
  cash_max: 0,
  total_retail: 0,
  cash_offered: 0,
  credit_offered: 0,
  cash_paid: 0,
  credit_paid: 0,
  bulk_cash_offered: 0,
  bulk_credit_offered: 0,
  bulk_cash_paid: 0,
  bulk_credit_paid: 0,
  cash_type: '',
  cash_reference: '',
  summary: '',
  status: 'active',
  buy_type: 'NA',
  total_cmf_qty: 0,
  total_minifig_qty: 0,
  total_animal_qty: 0,
  total_nib_qty: 0,
  total_used_qty: 0,
  total_bulk_qty: 0,
  total_misc_qty: 0,
  total_cmf_value: 0,
  total_minifig_value: 0,
  total_animal_value: 0,
  total_nib_value: 0,
  total_used_value: 0,
  total_bulk_value: 0,
  total_misc_value: 0,
  series_minifig_buy_lines: [],
  minifig_buy_lines: [],
  animal_buy_lines: [],
  new_set_buy_lines: [],
  used_set_buy_lines: [],
  bulk_buy_lines: [],
  misc_buy_lines: [],
  notes: [],
  numZero: 0,
});

const resetTotals = (buy: BuyModel): BuyModel => ({
  ...buy,
  total_retail: 0,
  cash_min: 0,
  cash_max: 0,
  credit_min: 0,
  credit_max: 0,
  total_minifig_qty: 0,
  total_animal_qty: 0,
  total_cmf_qty: 0,
  total_nib_qty: 0,
  total_used_qty: 0,
  total_bulk_qty: 0,
  total_misc_qty: 0,
  total_minifig_value: 0,
  total_animal_value: 0,
  total_cmf_value: 0,
  total_nib_value: 0,
  total_used_value: 0,
  total_bulk_value: 0,
  total_misc_value: 0,
  bulk_credit_paid: 0,
  bulk_cash_paid: 0,
  numZero: 0,
});

type BuyLineModel =
  | BulkBuyLineModel
  | NewSetBuyLineModel
  | MiscBuyLineModel
  | UsedSetBuyLineModel
  | MinifigBuyLineModel
  | MiscBuyLineModel
  | SeriesMinifigBuyLineModel;

export const BuyProvider: React.FC<BuyContextProps> = (
  props: BuyContextProps
) => {
  const navigate = useNavigate();
  const { children, unit, creator, onSave } = props;
  const { load, settings, status: settingsStatus } = useLoadBuySettings();
  const {
    status: saveStatus,
    error: saveError,
    save: saveBuy,
    buy: savedBuy,
    finalize: finalizeBuy,
    complete: completeBuy,
    cancel: cancelBuy,
  } = useSaveBuy();
  const [buy, setBuy] = React.useState<BuyModel>(newBuy(unit, creator));
  // use this to handle state transition navigation, default, newSave, newComplete
  const [showState, setShowState] = React.useState<string>('newSave');
  const { warnings } = useBuyWarnings(buy);

  React.useEffect(() => {
    if (unit) {
      load(unit.id);
    }
  }, [unit, load]);

  React.useEffect(() => {
    if (props.buy && settings && settingsStatus === api.success) {
      setBuy(props.buy);
      setShowState('default');
    }
  }, [props.buy, settings, settingsStatus]);

  const getSetting = (key: string): number => {
    if (settings.settings && key in settings.settings) {
      const keyTyped = key as keyof BuySettingModel;
      return +settings.settings[keyTyped] / 100;
    }
    return 0;
  };

  const getDiscount = (value: number, key: string): number => {
    const modifier = getSetting(key);
    if (modifier === 1) {
      return 0;
    } else if (modifier > 1) {
      return value * (1 - modifier);
    } else {
      return -1 * value * (modifier - 1);
    }
  };

  const calcNewSetTotals = (
    current: NewSetBuyLineModel
  ): NewSetBuyLineModel => {
    let offerDiscount = 0;
    let valueDiscount = 0;
    if (current.damaged) {
      valueDiscount += getDiscount(current.value, 'nib_damaged_adj');
    }
    if (current.opened) {
      valueDiscount += getDiscount(current.value, 'nib_open_adj');
    }
    const adjValue = current.value - offerDiscount;
    if (current.overstock) {
      offerDiscount += getDiscount(adjValue, 'nib_overstock_adj');
    }
    if (current.desirability === 'hot_seller') {
      offerDiscount += getDiscount(adjValue, 'nib_hot_seller_adj');
    } else if (current.desirability === 'mediocre_seller') {
      offerDiscount += getDiscount(adjValue, 'nib_mediocre_adj');
    } else if (current.desirability === 'slow_seller') {
      offerDiscount += getDiscount(adjValue, 'nib_dust_collector_adj');
    } else if (current.desirability === 'not_interested') {
      offerDiscount += getDiscount(adjValue, 'nib_not_interested_adj');
    }
    return {
      ...current,
      value_discount: valueDiscount,
      offer_discount: offerDiscount,
    };
  };

  const calcUsedSetTotals = (
    current: UsedSetBuyLineModel
  ): UsedSetBuyLineModel => {
    let offerDiscount = 0;
    let valueDiscount = 0;
    if (current.complete === 'ready_to_sell') {
      valueDiscount += getDiscount(
        current.value,
        'used_compl_ready_to_sell_adj'
      );
    } else if (current.complete === 'minor_work_needed') {
      valueDiscount += getDiscount(
        current.value,
        'used_compl_minor_work_needed_adj'
      );
    } else if (current.complete === 'partial_build') {
      valueDiscount += getDiscount(
        current.value,
        'used_compl_partial_build_adj'
      );
    } else if (current.complete === 'unbuilt') {
      valueDiscount += getDiscount(current.value, 'used_compl_unbuilt_adj');
    }
    const adjValue = current.value - valueDiscount;
    if (current.desirability === 'hot_seller') {
      offerDiscount += getDiscount(adjValue, 'used_hot_seller_adj');
    } else if (current.desirability === 'mediocre_seller') {
      offerDiscount += getDiscount(adjValue, 'used_mediocre_adj');
    } else if (current.desirability === 'slow_seller') {
      offerDiscount += getDiscount(adjValue, 'used_dust_collector_adj');
    } else if (current.desirability === 'not_interested') {
      offerDiscount += getDiscount(adjValue, 'used_not_interested_adj');
    }
    if (current.overstock) {
      offerDiscount += getDiscount(adjValue, 'used_overstock_adj');
    }
    if (current.dirty) {
      offerDiscount += getDiscount(adjValue, 'used_dirty_adj');
    }
    return {
      ...current,
      value_discount: valueDiscount,
      offer_discount: offerDiscount,
    };
  };

  const calcAnimalTotals = (
    current: AnimalBuyLineModel
  ): AnimalBuyLineModel => {
    let valueDiscount = 0;
    let offerDiscount = 0;
    if (current.condition === 'damaged') {
      valueDiscount += getDiscount(current.value, 'animal_damaged_adj');
    } else if (current.condition === 'used') {
      valueDiscount += getDiscount(current.value, 'animal_used_adj');
    } else if (current.condition === 'incomplete') {
      valueDiscount += getDiscount(current.value, 'animal_incomplete_adj');
    }
    const adjValue = current.value - valueDiscount;
    if (current.overstock) {
      offerDiscount += getDiscount(adjValue, 'animal_overstock_adj');
    }
    return {
      ...current,
      value_discount: valueDiscount,
      offer_discount: offerDiscount,
    };
  };

  const calcMinifigureTotals = (
    current: MinifigBuyLineModel
  ): MinifigBuyLineModel => {
    let valueDiscount = 0;
    let offerDiscount = 0;
    if (current.condition === 'damaged') {
      valueDiscount += getDiscount(current.value, 'minifig_damaged_adj');
    } else if (current.condition === 'used') {
      valueDiscount += getDiscount(current.value, 'minifig_used_adj');
    } else if (current.condition === 'incomplete') {
      valueDiscount += getDiscount(current.value, 'minifig_incomplete_adj');
    }
    const adjValue = current.value - valueDiscount;
    if (current.overstock) {
      offerDiscount += getDiscount(adjValue, 'minifig_overstock_adj');
    }
    return {
      ...current,
      value_discount: valueDiscount,
      offer_discount: offerDiscount,
    };
  };

  const calcCMFTotals = (
    current: SeriesMinifigBuyLineModel
  ): SeriesMinifigBuyLineModel => {
    let valueDiscount = 0;
    let offerDiscount = 0;
    if (current.condition === 'damaged') {
      valueDiscount += getDiscount(current.value, 'minifig_damaged_adj');
    } else if (current.condition === 'used') {
      valueDiscount += getDiscount(current.value, 'minifig_used_adj');
    } else if (current.condition === 'incomplete') {
      valueDiscount += getDiscount(current.value, 'minifig_incomplete_adj');
    }
    const adjValue = current.value - valueDiscount;
    if (current.overstock) {
      offerDiscount += getDiscount(adjValue, 'minifig_overstock_adj');
    }
    return {
      ...current,
      value_discount: valueDiscount,
      offer_discount: offerDiscount,
    };
  };

  const calcBulkTotals = (current: BulkBuyLineModel): BulkBuyLineModel => {
    let valueDiscount = 0;
    let offerDiscount = 0;
    if (current.premium_parts) {
      valueDiscount += getDiscount(current.value, 'bulk_premium_adj');
    }
    if (current.minifigs) {
      valueDiscount += getDiscount(current.value, 'bulk_minifig_adj');
    }
    const adjValue = current.value - valueDiscount;
    if (current.overstock) {
      offerDiscount += getDiscount(adjValue, 'bulk_overstock_adj');
    }
    if (current.dirty) {
      offerDiscount += getDiscount(adjValue, 'bulk_dirty_adj');
    }
    return {
      ...current,
      value_discount: valueDiscount,
      offer_discount: offerDiscount,
    };
  };

  const calcMiscTotals = (current: MiscBuyLineModel): MiscBuyLineModel => {
    let offerDiscount = 0;
    if (current.overstock) {
      offerDiscount = getDiscount(current.value, 'misc_overstock_adj');
    }
    return { ...current, offer_discount: offerDiscount };
  };

  const updateOffer = (buy: BuyModel, line: BuyLineModel, buyType: string) => {
    const adjValue = line.value - line.value_discount;
    buy.total_retail += line.quantity * adjValue;
    const offerValue = adjValue - line.offer_discount;
    buy.cash_max +=
      line.quantity * offerValue * getSetting(`${buyType}_cash_value_adj`);
    buy.cash_min +=
      line.quantity * offerValue * getSetting(`${buyType}_cash_minimum_adj`);
    buy.credit_max +=
      line.quantity * offerValue * getSetting(`${buyType}_credit_value_adj`);
    buy.credit_min +=
      line.quantity * offerValue * getSetting(`${buyType}_credit_minimum_adj`);
  };

  const recalcTotal = (currentBuy: BuyModel): BuyModel => {
    const buy: BuyModel = resetTotals(currentBuy);
    buy.minifig_buy_lines.forEach((line) => {
      if (!line.deleted) {
        updateOffer(buy, line, line.minifig ? 'minifig' : 'minifig_generic');
        buy.total_minifig_qty += +line.quantity;
        buy.total_minifig_value +=
          line.quantity * (line.value - line.value_discount);

        if (line.value == 0) {
          buy.numZero += 1;
        }
      }
    });
    buy.series_minifig_buy_lines.forEach((line) => {
      if (!line.deleted) {
        updateOffer(buy, line, 'minifig');
        buy.total_cmf_qty += +line.quantity;
        buy.total_cmf_value +=
          line.quantity * (line.value - line.value_discount);

        if (line.value == 0) {
          buy.numZero += 1;
        }
      }
    });
    buy.animal_buy_lines.forEach((line) => {
      if (!line.deleted) {
        updateOffer(buy, line, line.animal ? 'animal' : 'animal_generic');
        buy.total_animal_qty += +line.quantity;
        buy.total_animal_value +=
          line.quantity * (line.value - line.value_discount);

        if (line.value == 0) {
          buy.numZero += 1;
        }
      }
    });
    buy.new_set_buy_lines.forEach((line) => {
      if (!line.deleted) {
        updateOffer(buy, line, 'nib');
        buy.total_nib_qty += +line.quantity;
        buy.total_nib_value +=
          line.quantity * (line.value - line.value_discount);

        if (line.value == 0) {
          buy.numZero += 1;
        }
      }
    });
    buy.used_set_buy_lines.forEach((line) => {
      if (!line.deleted) {
        updateOffer(buy, line, 'used');
        buy.total_used_qty += +line.quantity;
        buy.total_used_value +=
          line.quantity * (line.value - line.value_discount);

        if (line.value == 0) {
          buy.numZero += 1;
        }
      }
    });
    buy.misc_buy_lines.forEach((line) => {
      if (!line.deleted) {
        updateOffer(buy, line, 'misc');
        buy.total_misc_qty += +line.quantity;
        buy.total_misc_value +=
          line.quantity * (line.value - line.value_discount);

        if (line.value == 0) {
          buy.numZero += 1;
        }
      }
    });

    //
    // we don't use updateOffer helper here for a number of reasons
    // 1. we need to adjust calculated quantity given the percentage of non lego that was entered
    // 2. A fixed offer is given for bulk, the buyer can always opt to pay more, but when mixed buys are involved, bulk cost is always going
    //    to be fixed and "separated out"
    //
    buy.bulk_buy_lines.forEach((line) => {
      if (!line.deleted) {
        if (line.value == 0) {
          buy.numZero += 1;
        }
        const adjQty = (line.quantity * (100 - line.non_lego)) / 100;
        let creditOffer = getSetting('bulk_credit_value_adj') * 100 * adjQty;
        let cashOffer = getSetting('bulk_cash_value_adj') * 100 * adjQty;
        if (line.dirty) {
          creditOffer -= creditOffer * (1 - getSetting('bulk_dirty_adj'));
          cashOffer -= cashOffer * (1 - getSetting('bulk_dirty_adj'));
        }
        if (line.overstock) {
          creditOffer -= creditOffer * (1 - getSetting('bulk_overstock_adj'));
          cashOffer -= cashOffer * (1 - getSetting('bulk_overstock_adj'));
        }
        if (line.premium_parts) {
          creditOffer -= creditOffer * (1 - getSetting('bulk_premium_adj'));
          cashOffer -= cashOffer * (1 - getSetting('bulk_premium_adj'));
        }
        if (line.minifigs) {
          creditOffer -= creditOffer * (1 - getSetting('bulk_minifig_adj'));
          cashOffer -= cashOffer * (1 - getSetting('bulk_minifig_adj'));
        }
        buy.total_bulk_qty += adjQty;
        buy.total_bulk_value += (line.value - line.value_discount) * adjQty;
        buy.bulk_cash_offered = cashOffer;
        buy.bulk_credit_offered = creditOffer;
        buy.credit_max += creditOffer;
        buy.cash_max += cashOffer;
        buy.credit_min += creditOffer;
        buy.cash_min += cashOffer;
        // attribute full cost of buy to bulk for bulk only buys
        if (buy.total_retail === 0) {
          line.cost_final =
            (buy.buy_type === 'cash' ? buy.cash_paid : buy.credit_paid) /
            adjQty;
          // attribute only bulk offer amount to bulk cost
        } else {
          line.cost_final =
            (buy.buy_type === 'cash' ? cashOffer : creditOffer) / adjQty;
        }

        if (buy.buy_type === 'trade') {
          buy.bulk_credit_paid += line.cost_final * adjQty;
        } else {
          buy.bulk_cash_paid += line.cost_final * adjQty;
        }
      }
    });
    const cost =
      buy.buy_type === 'cash'
        ? buy.cash_paid - buy.bulk_cash_paid
        : buy.credit_paid - buy.bulk_credit_paid;
    buy.minifig_buy_lines.forEach((line) => {
      if (!line.deleted) {
        const adjValue = line.value - line.value_discount;
        line.cost_final = (adjValue * cost) / buy.total_retail;
      }
    });
    buy.animal_buy_lines.forEach((line) => {
      if (!line.deleted) {
        const adjValue = line.value - line.value_discount;
        line.cost_final = (adjValue * cost) / buy.total_retail;
      }
    });
    buy.series_minifig_buy_lines.forEach((line) => {
      if (!line.deleted) {
        const adjValue = line.value - line.value_discount;
        line.cost_final = (adjValue * cost) / buy.total_retail;
      }
    });
    buy.new_set_buy_lines.forEach((line) => {
      if (!line.deleted) {
        const adjValue = line.value - line.value_discount;
        line.cost_final = (adjValue * cost) / buy.total_retail;
      }
    });
    buy.used_set_buy_lines.forEach((line) => {
      if (!line.deleted) {
        const adjValue = line.value - line.value_discount;
        line.cost_final = (adjValue * cost) / buy.total_retail;
      }
    });
    buy.misc_buy_lines.forEach((line) => {
      if (!line.deleted) {
        const adjValue = line.value - line.value_discount;
        line.cost_final = (adjValue * cost) / buy.total_retail;
      }
    });

    let lines = [];
    if (buy.total_cmf_qty > 0 || buy.total_minifig_qty > 0) {
      lines.push(`${buy.total_cmf_qty + buy.total_minifig_qty} Minifig(s)`);
    }
    if (buy.total_nib_qty > 0) {
      lines.push(`${buy.total_nib_qty} New Set(s)`);
    }
    if (buy.total_animal_qty > 0) {
      lines.push(`${buy.total_animal_qty} Animal(s)`);
    }
    if (buy.total_used_qty > 0) {
      lines.push(`${buy.total_used_qty} Used Set(s)`);
    }
    if (buy.total_bulk_qty > 0) {
      lines.push(`${buy.total_bulk_qty}g Bulk`);
    }
    if (buy.total_misc_qty > 0) {
      lines.push(`${buy.total_misc_qty} Miscellaneous Items`);
    }
    buy.summary = lines.join(', ');
    return buy;
  };

  const updateCMF = (updatedLine: SeriesMinifigBuyLineModel) => {
    buy.series_minifig_buy_lines = buy.series_minifig_buy_lines.map((line) => {
      if (line.key === updatedLine.key) {
        return calcCMFTotals(updatedLine);
      } else {
        return line;
      }
    });
    setBuy(recalcTotal(buy));
  };

  const addCMF = (line: SeriesMinifigBuyLineModel) => {
    line.key = buy.series_minifig_buy_lines.length + 1;
    line = calcCMFTotals(line);
    buy.series_minifig_buy_lines.push(calcCMFTotals(line));
    setBuy(recalcTotal(buy));
  };

  const deleteCMF = (key: number) => {
    buy.series_minifig_buy_lines = buy.series_minifig_buy_lines.map(
      (line: SeriesMinifigBuyLineModel) => {
        if (line.key === key) {
          line.deleted = true;
          return line;
        }
        return line;
      }
    );
    setBuy(recalcTotal(buy));
  };

  const updateMinifig = (updatedLine: MinifigBuyLineModel) => {
    buy.minifig_buy_lines = buy.minifig_buy_lines.map((line) => {
      if (line.key === updatedLine.key) {
        return calcMinifigureTotals(updatedLine);
      } else {
        return line;
      }
    });
    setBuy(recalcTotal(buy));
  };

  const addMinifig = (line: MinifigBuyLineModel) => {
    line.key = buy.minifig_buy_lines.length + 1;
    line = calcMinifigureTotals(line);
    buy.minifig_buy_lines.push(calcMinifigureTotals(line));
    setBuy(recalcTotal(buy));
  };

  const deleteMinifig = (key: number) => {
    buy.minifig_buy_lines = buy.minifig_buy_lines.map(
      (line: MinifigBuyLineModel) => {
        if (line.key === key) {
          line.deleted = true;
          return line;
        }
        return line;
      }
    );
    setBuy(recalcTotal(buy));
  };

  const updateAnimal = (updatedLine: AnimalBuyLineModel) => {
    buy.animal_buy_lines = buy.animal_buy_lines.map((line) => {
      if (line.key === updatedLine.key) {
        return calcAnimalTotals(updatedLine);
      } else {
        return line;
      }
    });
    setBuy(recalcTotal(buy));
  };

  const addAnimal = (line: AnimalBuyLineModel) => {
    line.key = buy.animal_buy_lines.length + 1;
    line = calcAnimalTotals(line);
    buy.animal_buy_lines.push(calcAnimalTotals(line));
    setBuy(recalcTotal(buy));
  };

  const deleteAnimal = (key: number) => {
    buy.animal_buy_lines = buy.animal_buy_lines.map(
      (line: AnimalBuyLineModel) => {
        if (line.key === key) {
          line.deleted = true;
          return line;
        }
        return line;
      }
    );
    setBuy(recalcTotal(buy));
  };

  const addNewSet = (line: NewSetBuyLineModel) => {
    line.key = buy.new_set_buy_lines.length + 1;
    line = calcNewSetTotals(line);
    buy.new_set_buy_lines.push(line);
    setBuy(recalcTotal(buy));
  };

  const updateNewSet = (updatedLine: NewSetBuyLineModel) => {
    buy.new_set_buy_lines = buy.new_set_buy_lines.map((line) => {
      if (line.key === updatedLine.key) {
        line = calcNewSetTotals(updatedLine);
      }
      return line;
    });
    setBuy(recalcTotal(buy));
  };

  const deleteNewSet = (key: number) => {
    buy.new_set_buy_lines = buy.new_set_buy_lines.map(
      (line: NewSetBuyLineModel) => {
        if (line.key === key) {
          line.deleted = true;
          return line;
        }
        return line;
      }
    );
    setBuy(recalcTotal(buy));
  };

  const addUsedSet = (line: UsedSetBuyLineModel) => {
    line.key = buy.used_set_buy_lines.length + 1;
    line = calcUsedSetTotals(line);
    buy.used_set_buy_lines.push(line);
    setBuy(recalcTotal(buy));
  };

  const updateUsedSet = (updatedLine: UsedSetBuyLineModel) => {
    buy.used_set_buy_lines = buy.used_set_buy_lines.map((line) => {
      if (line.key === updatedLine.key) {
        line = calcUsedSetTotals(updatedLine);
      }
      return line;
    });
    setBuy(recalcTotal(buy));
  };

  const deleteUsedSet = (key: number) => {
    buy.used_set_buy_lines = buy.used_set_buy_lines.map(
      (line: UsedSetBuyLineModel) => {
        if (line.key === key) {
          line.deleted = true;
          return line;
        }
        return line;
      }
    );
    setBuy(recalcTotal(buy));
  };

  const addBulk = (line: BulkBuyLineModel) => {
    line.key = buy.bulk_buy_lines.length + 1;
    line = calcBulkTotals(line);
    buy.bulk_buy_lines.push(line);
    setBuy(recalcTotal(buy));
  };

  const deleteBulk = (key: number) => {
    buy.bulk_buy_lines = buy.bulk_buy_lines.map((line: BulkBuyLineModel) => {
      if (line.key === key) {
        line.deleted = true;
      }
      return line;
    });
    setBuy(recalcTotal(buy));
  };

  const updateBulk = (updatedLine: BulkBuyLineModel) => {
    buy.bulk_buy_lines = buy.bulk_buy_lines.map((line) => {
      if (line.key === updatedLine.key) {
        line = calcBulkTotals(updatedLine);
      }
      return line;
    });
    setBuy(recalcTotal(buy));
  };

  const addMisc = (line: MiscBuyLineModel) => {
    line.key = buy.misc_buy_lines.length + 1;
    line = calcMiscTotals(line);
    buy.misc_buy_lines.push(line);
    setBuy(recalcTotal(buy));
  };

  const deleteMisc = (key: number) => {
    buy.misc_buy_lines = buy.misc_buy_lines.map((line: MiscBuyLineModel) => {
      if (line.key === key) {
        line.deleted = true;
      }
      return line;
    });
    setBuy(recalcTotal(buy));
  };

  const updateMisc = (updatedLine: MiscBuyLineModel) => {
    buy.misc_buy_lines = buy.misc_buy_lines.map((line) => {
      if (line.key === updatedLine.key) {
        line = calcMiscTotals(updatedLine);
      }
      return line;
    });
    setBuy(recalcTotal(buy));
  };

  const setCustomer = (customer: CustomerImportModel) => {
    setBuy({ ...buy, customer: customer });
  };

  const setCreator = (creator: UserModel) => {
    setBuy({ ...buy, creator: creator });
  };

  const updateOfferValue = (type: string, offer: number) => {
    if (type === 'trade') {
      setBuy({ ...buy, credit_offered: offer });
    }
    if (type === 'cash') {
      setBuy({ ...buy, cash_offered: offer });
    }
  };

  const updatePaidValue = (type: string, offer: number) => {
    const updatedBuy =
      type === 'trade'
        ? { ...buy, credit_paid: offer, cash_paid: 0 }
        : { ...buy, cash_paid: offer, credit_paid: 0 };
    setBuy(recalcTotal(updatedBuy));
  };

  const setUnit = (unit: UnitModel) => {
    setBuy({ ...buy, unit: unit });
  };

  const updateBuyType = (type: string) => {
    const updatedBuy = { ...buy, buy_type: type };
    setBuy(recalcTotal(updatedBuy));
  };

  const updateAsking = (value: number) => {
    setBuy({ ...buy, asking: value });
  };

  const updateCashType = (value: string, reference: string) => {
    setBuy({ ...buy, cash_type: value, cash_reference: reference });
  };

  const updateSummary = (summary: string) => {
    setBuy({ ...buy, summary: summary });
  };

  const updateSignature = (key: string, value: string) => {
    setBuy({ ...buy, [key]: value });
  };

  const revert = () => {
    const updatedBuy = { ...buy, status: 'active' };
    setShowState('newCancelled');
    setBuy(updatedBuy);
    saveBuy(updatedBuy);
  };

  const save = () => {
    const updatedBuy = recalcTotal(buy);
    setBuy(updatedBuy);
    saveBuy(updatedBuy);
  };

  const complete = () => {
    const completedBuy = recalcTotal(buy);
    setShowState('newComplete');
    setBuy(completedBuy);
    completeBuy(completedBuy);
  };

  const cancel = (note: string) => {
    setShowState('newCancelled');
    cancelBuy(buy, note);
  };

  const finalize = () => {
    const updatedBuy = recalcTotal(buy);
    setShowState('newFinalize');
    setBuy(updatedBuy);
    finalizeBuy(updatedBuy);
  };

  React.useEffect(() => {
    if (saveStatus === api.success && savedBuy) {
      setBuy(savedBuy);
      if (savedBuy.status === 'pending') {
        window.location.reload();
      } else if (showState === 'newSave') {
        navigate(`/buys/${savedBuy.id}`);
      } else if (showState === 'newCancelled') {
        setShowState('default');
        window.location.reload();
      } else if (showState === 'newComplete') {
        setShowState('default');
        navigate({
          pathname: `/buys/${savedBuy.id}`,
          search: createSearchParams({
            view: 'form',
          }).toString(),
        });
      } else {
        setShowState('default');
      }
      if (onSave) {
        onSave(true, '');
      }
    } else if (saveStatus === api.error) {
      if (onSave) {
        onSave(false, saveError);
      }
    }
  }, [savedBuy, saveStatus, saveError]);

  return (
    <BuyContext.Provider
      value={{
        buy,
        warnings,
        updateCMF,
        addCMF,
        deleteCMF,
        updateMinifig,
        addMinifig,
        deleteMinifig,
        updateAnimal,
        addAnimal,
        deleteAnimal,
        addNewSet,
        updateNewSet,
        deleteNewSet,
        addUsedSet,
        updateUsedSet,
        deleteUsedSet,
        addBulk,
        deleteBulk,
        updateBulk,
        addMisc,
        deleteMisc,
        updateMisc,
        setCustomer,
        setCreator,
        updateOfferValue,
        updatePaidValue,
        updateBuyType,
        updateCashType,
        updateAsking,
        updateSummary,
        updateSignature,
        setUnit,
        save,
        finalize,
        complete,
        cancel,
        revert,
        saveStatus,
        saveError,
        setBuy,
      }}
    >
      {children}
    </BuyContext.Provider>
  );
};
