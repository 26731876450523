import * as React from 'react';
import { debounce } from 'lodash';
import { LinearProgress, Grid, Button } from '@mui/material';

import { useCMFSearch } from 'api';
import { api } from 'lib';
import { SearchThumbnail, CardModal } from 'components';
import { SeriesMinifigModel } from 'model';
import { CMFCard } from '../cmf_card';

interface Props {
  query: string;
  onClick?: (payload: SeriesMinifigModel) => void;
  onCheck?: (payload: SeriesMinifigModel, checked: boolean) => void;
  size?: number;
  maxResults?: number;
}

export const CMFSearch: React.FC<Props> = ({
  query,
  onClick,
  onCheck,
  maxResults,
  size,
}: Props) => {
  const { search, status, total, minifigs } = useCMFSearch();
  const [page, setPage] = React.useState({ index: 0, total: 0 });
  const [preview, setPreview] = React.useState<SeriesMinifigModel | null>(null);
  const handleOpen = (minifig: SeriesMinifigModel) => setPreview(minifig);
  const handleClose = () => setPreview(null);
  const doSearch = React.useMemo(() => debounce(search, 500), [search]);
  React.useEffect(() => {
    if (query.length > 3) {
      doSearch(query, page.index, maxResults ? maxResults : 18);
    }
  }, [query, page, doSearch, maxResults]);

  React.useEffect(() => {
    setPage({
      index: 0,
      total: Math.ceil(total / (maxResults ? maxResults : 18)),
    });
  }, [total, maxResults]);

  const nextPage = () => {
    const newIndex = page.index + 1;
    setPage({
      index: newIndex > page.total - 1 ? page.total - 1 : newIndex,
      total: page.total,
    });
  };
  const prevPage = () => {
    const newIndex = page.index - 1;
    setPage({ index: newIndex < 0 ? 0 : newIndex, total: page.total });
  };

  if (status === api.loading) {
    return (
      <div style={{ margin: '32px 0', width: 800 }}>
        <LinearProgress />
      </div>
    );
  } else if (status === api.success) {
    return (
      <>
        <Grid container alignItems='center' spacing={1}>
          {minifigs &&
            minifigs.map((minifig) => (
              <Grid item xs={12} md={2} key={minifig.id}>
                <div onClick={() => handleOpen(minifig)}>
                  <SearchThumbnail
                    imageUrl={minifig.rebrickable_image_url}
                    imageTitle={minifig.name}
                    payload={minifig}
                    onClick={onClick}
                    onCheck={onCheck}
                    height={size ? size : 120}
                    width={size ? size : 120}
                  >
                    {minifig.bricklink_id} - {minifig.name}
                  </SearchThumbnail>
                </div>
              </Grid>
            ))}
        </Grid>
        <Grid container alignItems='center' spacing={1} sx={{ marginTop: 1 }}>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ textAlign: 'right', fontSize: '0.9em', marginBottom: 20 }}
          >
            <Button onClick={prevPage}>
              <strong>&lt;</strong>
            </Button>
            <Button onClick={nextPage}>
              <strong>&gt;</strong>
            </Button>
            page {page.index + 1} of {page.total} (Total {total})
          </Grid>
        </Grid>
        <CardModal open={preview !== null} onClose={handleClose}>
          <CMFCard minifig={preview} />
        </CardModal>
      </>
    );
  } else {
    return <></>;
  }
};
