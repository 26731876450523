import * as React from 'react';
import { Box, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { formatCurrency } from 'lib';
import { BuyModel, MinifigBuyLineModel, MinifigModel, SeriesMinifigBuyLineModel, SeriesMinifigModel } from 'model';
import { CMFCard, CardModal, MinifigCard } from 'components';

interface Props {
  buy: BuyModel;
}

export const MinifigSection: React.FC<Props> = ({ buy }: Props) => {
  const [selectedMinifig, setSelectedMinifig] = React.useState<MinifigModel | null>(null);
  const handleCloseMinifigCard = () => setSelectedMinifig(null);
  const [selectedCMF, setSelectedCMF] = React.useState<SeriesMinifigModel | null>(null);
  const handleCloseCMFCard = () => setSelectedCMF(null);
  let total = 0;

  const getMinifigRows: MinifigBuyLineModel[] = React.useMemo(() => {
    return buy.minifig_buy_lines.filter((row) => !row.deleted);
  }, [buy.minifig_buy_lines]);

  const getCMFRows: SeriesMinifigBuyLineModel[] = React.useMemo(() => {
    return buy.series_minifig_buy_lines.filter((row) => !row.deleted);
  }, [buy.series_minifig_buy_lines]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Box sx={{ p: 2, fontWeight: 700 }}>Minifigs</Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="Minifigures">
        <TableHead>
          <TableRow>
            <TableCell width={"40%"}>Description</TableCell>
            <TableCell align="left">Category</TableCell>
            <TableCell align="left">Condition</TableCell>
            <TableCell width={50} align="right">Qty</TableCell>
            <TableCell width={75} align="right">Cost</TableCell>
            <TableCell width={75} align="right">Value</TableCell>
            <TableCell width={75} align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getMinifigRows.map((row) => {
            const adjValue = row.value - row.value_discount;
            total += row.cost_final * row.quantity;
            return (
              <TableRow key={row.id}>
                <TableCell>
                  {row.minifig ? <Link onClick={() => setSelectedMinifig(row.minifig || null)}>{row.description}</Link> : row.description}
                  <br />
                  {row.notes}
                </TableCell>
                <TableCell>Minifigure</TableCell>
                <TableCell align="left">{row.condition}</TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
                <TableCell align="right">{formatCurrency(row.cost_final)}</TableCell>
                <TableCell align="right">{formatCurrency(adjValue)}</TableCell>
                <TableCell align="right">{formatCurrency(row.quantity * adjValue)}</TableCell>
              </TableRow>
            );
          })}
          {getCMFRows.map((row) => {
            const adjValue = row.value - row.value_discount;
            total += row.cost_final * row.quantity;
            return (
              <TableRow key={row.id}>
                <TableCell>
                  <Link onClick={() => setSelectedCMF(row.series_minifig)}>{row.series_minifig.bricklink_id} - {row.series_minifig.name}</Link>
                  <br />{row.notes}
                </TableCell>
                <TableCell>CMF</TableCell>
                <TableCell align="left">{row.condition}</TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
                <TableCell align="right">{formatCurrency(row.cost_final)}</TableCell>
                <TableCell align="right">{formatCurrency(adjValue)}</TableCell>
                <TableCell align="right">{formatCurrency(row.quantity * adjValue)}</TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell colSpan={2} />
            <TableCell align="right"></TableCell>
            <TableCell align="right">{buy.total_minifig_qty + buy.total_cmf_qty}</TableCell>
            <TableCell align="right">{formatCurrency(total)}</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">{formatCurrency(buy.total_minifig_value + buy.total_cmf_value)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardModal open={selectedMinifig !== null} onClose={handleCloseMinifigCard}>
        <MinifigCard minifig={selectedMinifig} />
      </CardModal>
      <CardModal open={selectedCMF !== null} onClose={handleCloseCMFCard}>
        <CMFCard minifig={selectedCMF} />
      </CardModal>
    </TableContainer >
  );
};