import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DataGrid, GridRowParams } from '@mui/x-data-grid';

import { api } from 'lib';
import { useHeartlandAudits } from 'api';
import { AuditColumns } from 'components';

interface Props {
  heartlandId: number;
}

export const AuditFields: React.FC<Props> = (props) => {
  const { status, auditLogs, total, fetchAudits } = useHeartlandAudits(
    props.heartlandId
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: parseInt(searchParams.get('pageNum') || '0', 10),
  });
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchAudits(paginationModel.page, paginationModel.pageSize);

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('pageNum', paginationModel.page.toString());
    setSearchParams(newSearchParams);
  }, [paginationModel, fetchAudits]);

  const rowClick = (params: GridRowParams<any>) => {
    navigate(`/audits/${params.id}`);
  };

  if (status === api.error) {
    <div>Unable to load audit logs</div>;
  }
  return (
    <>
      <DataGrid
        rows={auditLogs}
        columns={AuditColumns}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        rowCount={total}
        paginationMode='server'
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick
        onRowClick={rowClick}
        getRowClassName={() => 'clickable-row'}
        sx={{
          '.clickable-row:hover': {
            cursor: 'pointer',
          },
        }}
      />
    </>
  );
};
