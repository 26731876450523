import * as React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { HideOnPrint } from 'components';
import { formatCurrency } from 'lib';
import { MinifigBuyLineModel } from 'model';
import { MinifigBuyEntryRow } from './minifig_buy_entry_row';
import { BuyContext, TBuyContext } from '../../../buy_context';


export const MinifigSectionInput: React.FC = () => {
  const { buy } = React.useContext(BuyContext) as TBuyContext;

  const getRows: MinifigBuyLineModel[] = React.useMemo(() => {
    return buy.minifig_buy_lines.filter((row) => !row.deleted);
  }, [buy.minifig_buy_lines, buy.minifig_buy_lines.length]);



  return (
    <TableContainer component={Paper} sx={{ background: "#f0f0f3" }}>
      <Box sx={{ p: 2, fontWeight: 700 }}>Minifigs</Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="Minifigures">
        <TableHead>
          <TableRow>
            <TableCell width={64}></TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Pricing</TableCell>
            <TableCell width={130}>Condition</TableCell>
            <TableCell width={80} align="center">Over</TableCell>
            <TableCell width={80} align="center">Qty</TableCell>
            <TableCell width={130}>
              <HideOnPrint>
                Value
              </HideOnPrint>
            </TableCell>
            <TableCell width={80} align="right">
              <HideOnPrint>
                Adj Value
              </HideOnPrint>
            </TableCell>
            <TableCell width={80} align="right">
              <HideOnPrint>
                Total
              </HideOnPrint>
            </TableCell>
            <TableCell width={120}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows.map((row) => (<MinifigBuyEntryRow key={row.id || row.key} row={row} />))}
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell align="right">
              Total
            </TableCell>
            <TableCell align="center">
              {buy.total_minifig_qty}
            </TableCell>
            <TableCell align="right" colSpan={2}>
            </TableCell>
            <TableCell align="right">
              <HideOnPrint>
                {formatCurrency(buy.total_minifig_value)}
              </HideOnPrint>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer >
  );
};