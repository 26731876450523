import * as React from "react";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";

import { HideOnPrint } from 'components';
import { formatCurrency } from "lib";
import { MiscBuyLineModel } from "model";
import { BuyContext, TBuyContext } from "../../../buy_context";
import { MiscBuyEntryRow } from "./misc_buy_entry_row";


export const MiscSectionInput: React.FC = () => {
  const { buy } = React.useContext(BuyContext) as TBuyContext;


  const getRows: MiscBuyLineModel[] = React.useMemo(() => {
    return buy.misc_buy_lines.filter((row) => !row.deleted);
  }, [buy.misc_buy_lines, buy.misc_buy_lines.length]);

  return (
    <TableContainer component={Paper} sx={{ background: "#ffffe7" }}>
      <Box sx={{ p: 2, fontWeight: 700 }}>Miscellaneous</Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="NewSetetures">
        <TableHead>
          <TableRow>
            <TableCell width="400">Description</TableCell>
            <TableCell align="center">Modifiers</TableCell>
            <TableCell align="center">Qty</TableCell>
            <TableCell align="right">
              <HideOnPrint>
                Value
              </HideOnPrint>
            </TableCell>
            <TableCell align="right">
              <HideOnPrint>
                Adj Value
              </HideOnPrint>
            </TableCell>
            <TableCell align="right">
              <HideOnPrint>
                Total
              </HideOnPrint>
            </TableCell>
            <TableCell width="120"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows.map((row) => (<MiscBuyEntryRow key={row.id || row.key} row={row} />))}
          <TableRow>
            <TableCell colSpan={1} />
            <TableCell align="right">
              Total
            </TableCell>
            <TableCell align="center">
              {buy.total_misc_qty}
            </TableCell>
            <TableCell align="right" colSpan={2}> </TableCell>
            <TableCell align="right">
              <HideOnPrint>
                {formatCurrency(buy.total_misc_value)}
              </HideOnPrint>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer >
  );
};
