import * as React from "react";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";

import { HideOnPrint } from 'components';
import { formatCurrency } from "lib";
import { UsedSetBuyLineModel } from "model";
import { BuyContext, TBuyContext } from "../../../buy_context";
import { UsedSetBuyEntryRow } from "./used_set_buy_entry_row";

export const UsedSetSectionInput: React.FC = () => {
  const { buy } = React.useContext(BuyContext) as TBuyContext;

  const getRows: UsedSetBuyLineModel[] = React.useMemo(() => {
    return buy.used_set_buy_lines.filter((row) => !row.deleted).sort((a, b) => a.lego_set.set_number > b.lego_set.set_number ? 1 : -1);
  }, [buy.used_set_buy_lines, buy.used_set_buy_lines.length]);

  return (
    <TableContainer component={Paper} sx={{ background: "#f9f9f0" }}>
      <Box sx={{ p: 2, fontWeight: 700 }}>Used Sets</Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="Use Sets">
        <TableHead>
          <TableRow>
            <TableCell width="400">Description</TableCell>
            <TableCell align="left">
              <HideOnPrint>
                Pricing
              </HideOnPrint>
            </TableCell>
            <TableCell align="left" width="95">Modifiers</TableCell>
            <TableCell>Completeness</TableCell>
            <TableCell>Desirablty</TableCell>
            <TableCell width={70} align="center">Qty</TableCell>
            <TableCell width={150} align="center">
              <HideOnPrint>
                Value
              </HideOnPrint>
            </TableCell>
            <TableCell width={68} align="right">
              <HideOnPrint>
                Adj Value
              </HideOnPrint>
            </TableCell>
            <TableCell width={68} align="right">
              <HideOnPrint>
                Total
              </HideOnPrint>
            </TableCell>
            <TableCell width={120}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows.map((row) => {
            return (<UsedSetBuyEntryRow key={row.id || row.key} row={row} />);
          })}
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell align="right">Total</TableCell>
            <TableCell align="center">{buy.total_used_qty}</TableCell>
            <TableCell align="right" colSpan={2}></TableCell>
            <TableCell align="right">
              <HideOnPrint>
                {formatCurrency(buy.total_used_value)}
              </HideOnPrint>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer >
  );
};
