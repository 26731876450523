import * as React from "react";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box, Link } from "@mui/material";

import { formatCurrency } from "lib";
import { BuyModel, LegoSetModel, UsedSetBuyLineModel } from "model";
import { CardModal, LegoSetCard } from "components";

interface Props {
  buy: BuyModel;
}

const condition = (value: string) => {
  switch (value) {
    case "default":
      return "Default";
    case "ready_to_sell":
      return "Ready to sell";
    case "minor_work_needed":
      return "Minor Work Needed";
    case "partial_build":
      return "Partial Build";
    case "unbuilt":
      return "Unbuilt";
    default:
      return "Unknown";
  }
};

const desirability = (value: string) => {
  switch (value) {
    case "hot_seller":
      return "Hot";
    case "good_seller":
      return "Good";
    case "mediocre_seller":
      return "Mediocre";
    default:
      return "Unknown";
  }
};

export const UsedSetSection: React.FC<Props> = ({ buy }: Props) => {
  const [selectedSet, setSelectedSet] = React.useState<LegoSetModel | null>(null);
  const handleCloseCard = () => setSelectedSet(null);
  let total = 0;

  const getRows: UsedSetBuyLineModel[] = React.useMemo(() => {
    return buy.used_set_buy_lines.filter((row) => !row.deleted).sort((a, b) => a.lego_set.set_number > b.lego_set.set_number ? 1 : -1);
  }, [buy.used_set_buy_lines]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Box sx={{ p: 2, fontWeight: 700 }}>Used</Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="NewSetetures">
        <TableHead>
          <TableRow>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Category</TableCell>
            <TableCell align="left">Condition</TableCell>
            <TableCell align="left">Desirability</TableCell>
            <TableCell width={50} align="right">Qty</TableCell>
            <TableCell width={80} align="right">Cost</TableCell>
            <TableCell width={80} align="right">Value</TableCell>
            <TableCell width={80} align="right">Total</TableCell>
            {/* <TableCell width={200}></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows.map((row) => {
            const adjValue = row.value - row.value_discount;
            total += row.cost_final * row.quantity;
            return (
              <TableRow key={row.id}>
                <TableCell>
                  <Link onClick={() => setSelectedSet(row.lego_set)}>{row.lego_set.set_number} {row.lego_set.name}</Link>
                  <br />
                  {row.notes}
                </TableCell>
                <TableCell>{row.lego_set.category}</TableCell>
                <TableCell align="left">
                  {condition(row.complete)}
                  <br />
                  {row.certifiable ? 'Certifiable' : ''}
                </TableCell>
                <TableCell align="left">{desirability(row.desirability)}</TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
                <TableCell align="right">{formatCurrency(row.cost_final)}</TableCell>
                <TableCell align="right">{formatCurrency(adjValue)}</TableCell>
                <TableCell align="right">{formatCurrency(row.quantity * adjValue)}</TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell align="right" colSpan={4} />
            <TableCell align="right" sx={{ fontWeight: 700 }}>{buy.total_used_qty}</TableCell>
            <TableCell align="right">{formatCurrency(total)}</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right" sx={{ fontWeight: 700 }}>{formatCurrency(buy.total_used_value)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <CardModal open={selectedSet !== null} onClose={handleCloseCard} >
        <LegoSetCard legoSet={selectedSet} />
      </CardModal>
    </TableContainer>
  );
};