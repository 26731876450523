import * as React from 'react';
import {
  TableRow,
  TableCell,
  Box,
  IconButton,
  Link,
  Checkbox as MUICheckbox,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { NewSetBuyLineModel } from 'model';
import {
  CardModal,
  Checkbox,
  CurrencyInput,
  HideOnPrint,
  HideOnScreen,
  DesirabilitySelector,
  EbaySetLink,
  LegoSetCard,
  NotesInput,
  QuantityInput,
} from 'components';
import { formatCurrency } from 'lib';
import { BuyContext, TBuyContext } from '../../../buy_context';
import PriceButton from '../price_button';

interface Props {
  row: NewSetBuyLineModel;
}

export const NewSetBuyEntryRow: React.FC<Props> = ({ row }: Props) => {
  const { deleteNewSet, updateNewSet } = React.useContext(BuyContext) as TBuyContext;
  const [showCard, setShowCard] = React.useState<boolean>(false);
  const handleCloseCard = () => setShowCard(false);
  const [checked, setChecked] = React.useState<boolean>(false);

  const update = (prop: string, value: string | number | boolean) => {
    updateNewSet({ ...row, [prop]: value });
  };

  const remove = (id: number) => deleteNewSet(id);

  const adjValue = React.useMemo(
    () => row.value - row.value_discount,
    [row.value, row.value_discount]
  );

  return (
    <TableRow key={row.key}>
      <TableCell>
        <Box sx={{ mb: 1, fontWeight: 600 }}>
          <Link onClick={() => setShowCard(true)}>
            {row.lego_set.set_number} &mdash; {row.lego_set.name} (
            {row.lego_set.num_minifigs} minifigs)
          </Link>
          <CardModal open={showCard} onClose={handleCloseCard}>
            <LegoSetCard legoSet={row.lego_set} />
          </CardModal>
        </Box>
        <HideOnPrint>
          <NotesInput
            value={row.notes}
            onChange={(value: string) => update('notes', value)}
          />
        </HideOnPrint>
        <HideOnScreen>{row.notes}</HideOnScreen>
      </TableCell>
      <TableCell>
        <HideOnPrint>
          <EbaySetLink item={row.lego_set} condition='new' />
          <br />
          <Link
            target='_bs'
            href={`https://brickset.com/sets/${row.lego_set.brickset_id}`}
          >
            Brickset
          </Link>
          <br />
          <Link
            target='_bl'
            href={`https://www.bricklink.com/v2/catalog/catalogitem.page?S=${row.lego_set.bricklink_id}`}
          >
            Bricklink
          </Link>
          <br />
        </HideOnPrint>
      </TableCell>

      <TableCell>
        <Checkbox
          checked={row.opened}
          label='Open Box'
          onChange={(e) => update('opened', e.target.checked)}
        />
        <br />
        <Checkbox
          checked={row.damaged}
          label='Damaged'
          onChange={(e) => update('damaged', e.target.checked)}
        />
        <br />
        <Checkbox
          checked={row.overstock}
          label='Overstock'
          onChange={(e) => update('overstock', e.target.checked)}
        />
        <br />
      </TableCell>

      <TableCell>
        <DesirabilitySelector
          value={row.desirability}
          onSelect={(value) => update('desirability', value)}
        />
      </TableCell>
      <TableCell align='center'>
        <QuantityInput
          quantity={row.quantity}
          onChange={(value) => update('quantity', value)}
        />
      </TableCell>
      <TableCell align='center'>
        <HideOnPrint>
            <CurrencyInput
              value={row.value}
              label={''}
              onChange={(e) => update('value', +e.target.value)}
              error={row.value === 0}
            />
            <PriceButton legoSet={row.lego_set} onUpdate={(value) => update('value', value)} department='New Sets' />
        </HideOnPrint>
      </TableCell>
      <TableCell align='right'>
        <HideOnPrint>{formatCurrency(adjValue, false)}</HideOnPrint>
      </TableCell>
      <TableCell align='right'>
        <HideOnPrint>
          {formatCurrency(row.quantity * adjValue, false)}
        </HideOnPrint>
      </TableCell>
      <TableCell>
        <HideOnPrint>
          <IconButton aria-label='delete' onClick={() => remove(row.key)}>
            {' '}
            <DeleteIcon />
          </IconButton>
          <MUICheckbox onChange={(e) => setChecked(e.target.checked)} />
        </HideOnPrint>
      </TableCell>
    </TableRow>
  );
};
