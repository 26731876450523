import * as React from 'react';
import { api } from 'lib';
import { AuditModel } from 'model';
import { StatusCodes } from 'http-status-codes';

interface Props {
  status: string;
  error: string;
  auditLogs: AuditModel[];
  total: number;
  fetchAudits: (page?: number, pageSize?: number) => void;
}

export const useHeartlandAudits = (heartlandId: number): Props => {
  const [status, setStatus] = React.useState(api.idle);
  const [error, setError] = React.useState<string>('');
  const [total, setTotal] = React.useState<number>(0);
  const [auditLogs, setAuditLogs] = React.useState<AuditModel[]>([]);

  const fetchAudits = React.useCallback(
    async (pageNum = 0, pageSize = 0) => {
      setStatus(api.loading);
      const resp = await api.get(`/api/heartlands/${heartlandId}/audits`, {
        page: pageNum.toString(),
        size: pageSize.toString(),
      });
      if (resp.status === StatusCodes.OK) {
        setStatus(api.success);
        setAuditLogs(resp.body.audit_history);
        setTotal(resp.body.total);
      } else {
        setStatus(api.error);
        setError(resp.body);
      }
    },
    [heartlandId]
  );

  return {
    status,
    error,
    auditLogs,
    total,
    fetchAudits,
  };
};
