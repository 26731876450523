import * as React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box, LinearProgress, Link, Tab, Tabs } from '@mui/material';

import { api } from 'lib';
import { useGetHeartland } from 'api';
import { NotFound, RewardsFormTab } from 'components';
import useStyles from './styles';
import {
  CustomFieldsList,
  HeartlandSettingsTab,
  AuditFields,
} from './components';
import { UnitsTab } from './components/units_tab';

function tabMap(index: number) {
  switch (index) {
    case 0:
      return 'settings';
    case 1:
      return 'units';
    case 2:
      return 'signup';
    case 3:
      return 'custom-fields';
    case 4:
      return 'audits';
    default:
      return 'settings';
  }
}

function a11yProps(index: string) {
  return {
    id: `heartland-tab-${index}`,
    'aria-controls': `heartland-tabpanel-${index}`,
  };
}

export const HeartlandShow: React.FC = () => {
  const flags = useFlags();
  const { getHeartland } = useGetHeartland();
  const { heartland_id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = React.useState(() => {
    return searchParams.get('tab') || 'settings';
  });

  const classes = useStyles();

  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    const tabName = tabMap(newTab);
    setSearchParams({ tab: tabName });
    setTab(tabName);
  };

  const heartland = React.useMemo(() => getHeartland.heartland, [getHeartland]);

  const customFields = React.useMemo(() => {
    if (
      heartland &&
      heartland.customer_custom_fields &&
      heartland.item_custom_fields
    ) {
      return heartland.customer_custom_fields?.concat(
        heartland.item_custom_fields
      );
    }
    return [];
  }, [heartland]);

  React.useEffect(() => {
    if (heartland_id) {
      getHeartland.byId(parseInt(heartland_id));
    }
  }, [heartland_id, getHeartland.byId]);

  if (getHeartland.status === api.loading) {
    return (
      <div style={{ margin: '32px 0', width: 800 }}>
        <LinearProgress />
      </div>
    );
  } else if (getHeartland.status === api.success && heartland) {
    return (
      <div className={classes.show}>
        <h2>
          <Link href='/heartlands'>Heartlands</Link> :: {heartland.domain}{' '}
        </h2>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
          <Tabs value={tab} onChange={handleChange} aria-label='Heartland Tabs'>
            <Tab label='Settings' {...a11yProps('settings')} />
            <Tab label='Units' {...a11yProps('units')} />
            <Tab label='Rewards Signup Form' {...a11yProps('signup')} />
            <Tab label='Custom Fields' {...a11yProps('custom-fields')} />
            {flags.audit && <Tab label='Audits' {...a11yProps('audits')} />}
          </Tabs>
        </Box>
        <Box
          role='tabpanel'
          hidden={tab !== 'settings'}
          id='heartland-tabpanel-0'
          aria-labelledby='heartland-tab-panel-0'
        >
          <HeartlandSettingsTab heartland={heartland} />
        </Box>
        <Box
          role='tabpanel'
          hidden={tab !== 'units'}
          id='heartland-tabpanel-1'
          aria-labelledby='heartland-tab-panel-1'
        >
          <UnitsTab heartland={heartland} />
        </Box>
        <Box
          role='tabpanel'
          hidden={tab !== 'signup'}
          id='heartland-tabpanel-2'
          aria-labelledby='heartland-tab-panel-2'
        >
          <RewardsFormTab
            rewardsSignupForm={heartland.rewards_signup_form}
            customFields={heartland.customer_custom_fields || []}
          />
        </Box>
        <Box
          role='tabpanel'
          hidden={tab !== 'custom-fields'}
          id='heartland-tabpanel-3'
          aria-labelledby='heartland-tab-panel-3'
        >
          <CustomFieldsList
            heartlandId={heartland.id}
            fields={customFields || []}
          />
        </Box>
        <Box
          role='tabpanel'
          hidden={tab !== 'audits'}
          id='heartland-tabpanel-4'
          aria-labelledby='heartland-tab-panel-4'
        >
          <AuditFields heartlandId={heartland.id} />
        </Box>
      </div>
    );
  } else if (getHeartland.status === api.error) {
    return <NotFound />;
  }
  return <></>;
};
