import * as React from 'react';
import {
  TableRow,
  TableCell,
  Box,
  Checkbox as MUICheckbox,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { BulkBuyLineModel } from 'model';
import {
  Checkbox,
  CurrencyInput,
  HideOnPrint,
  HideOnScreen,
  NotesInput,
  PercentageInput,
  QuantityInput,
} from 'components';
import { formatCurrency } from 'lib';
import { BuyContext, TBuyContext } from '../../../buy_context';
interface Props {
  row: BulkBuyLineModel;
}

export const BulkBuyEntryRow: React.FC<Props> = ({ row }: Props) => {
  const { deleteBulk, updateBulk } = React.useContext(
    BuyContext
  ) as TBuyContext;

  const remove = (id: number) => deleteBulk(id);
  const update = (prop: string, value: string | number | boolean) =>
    updateBulk({ ...row, [prop]: value });
  const adjQty = React.useMemo(
    () => (row.quantity * (100 - row.non_lego)) / 100,
    [row]
  );
  const [checked, setChecked] = React.useState<boolean>(false);

  return (
    <TableRow
      key={row.key}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <Box sx={{ mb: 1, fontWeight: 600 }}>{row.description}</Box>
        <HideOnPrint>
          <NotesInput
            value={row.notes}
            onChange={(value: string) => update('notes', value)}
          />
        </HideOnPrint>
        <HideOnScreen>{row.notes}</HideOnScreen>
      </TableCell>
      <TableCell align='center'>
        <PercentageInput
          value={row.non_lego}
          max100
          onChange={(value) => update('non_lego', value)}
          label={''}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          label='Dirty'
          checked={row.dirty}
          onChange={(e) => update('dirty', e.target.checked)}
        />
        <br />
        <Checkbox
          label='Overstock'
          checked={row.overstock}
          onChange={(e) => update('overstock', e.target.checked)}
        />
        <br />
        <Checkbox
          label='Premium Parts'
          checked={row.premium_parts}
          onChange={(e) => update('premium_parts', e.target.checked)}
        />
        <br />
        <Checkbox
          label='Minifigs'
          checked={row.minifigs}
          onChange={(e) => update('minifigs', e.target.checked)}
        />
      </TableCell>
      <TableCell align='center'>
        <QuantityInput
          quantity={row.quantity}
          onChange={(value) => update('quantity', value)}
          decimal
          width={80}
        />
      </TableCell>
      <TableCell align='center'>
        <HideOnPrint>
          <Box
            sx={{
              border: row.value === 0 ? '2.5px solid #f57c00' : '',
              borderRadius: '7px',
              p: 0.5,
              width: 'fit-content',
            }}
          >
            <CurrencyInput
              value={row.value}
              label={''}
              onChange={(e) => update('value', +e.target.value)}
            />
          </Box>
        </HideOnPrint>
      </TableCell>
      <TableCell align='right' sx={{ width: 100 }}>
        <HideOnPrint>
          {formatCurrency(row.value - row.value_discount)}
        </HideOnPrint>
      </TableCell>
      <TableCell align='right' sx={{ width: 120 }}>
        <HideOnPrint>
          {formatCurrency(row.quantity * (row.value - row.value_discount))}
        </HideOnPrint>
      </TableCell>
      <TableCell>
        <HideOnPrint>
          <IconButton aria-label='delete' onClick={() => remove(row.key)}>
            {' '}
            <DeleteIcon />
          </IconButton>
          <MUICheckbox onChange={(e) => setChecked(e.target.checked)} />
        </HideOnPrint>
      </TableCell>
    </TableRow>
  );
};
