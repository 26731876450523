import * as React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  Grid,
  Divider,
  TextField,
  Alert,
  FormControl,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  SelectChangeEvent,
} from '@mui/material';
import { debounce } from 'lodash';

import { api, formatCurrency, SessionContext, TSessionContext } from 'lib';
import { BuyContext, TBuyContext } from '../../../buy_context';
import { CurrencyInput } from 'components';
import { BuySettingModel } from 'model';
import { WarningDisplay } from 'components/warnings';

interface Props {
  open: boolean;
  settings: BuySettingModel | null;
  onClose: () => void;
  onComplete: () => void;
}

const calcMargin = (cost: any, total: any) => {
  if (!total || !cost) {
    return '0.0%';
  } else {
    const margin = (cost / total) * 100;
    if (margin < 0) {
      return '0.0%';
    }
    return `${Math.round(margin * 10) / 10}%`;
  }
};

export const CompleteModal: React.FC<Props> = (props: Props) => {
  const {
    buy,
    save,
    finalize,
    updatePaidValue,
    updateAsking,
    updateCashType,
    updateSummary,
    updateOfferValue,
    updateBuyType,
    saveStatus,
    warnings,
  } = React.useContext(BuyContext) as TBuyContext;
  const [error, setError] = React.useState<string | null>(null);
  const [summary, setSummary] = React.useState<string>(buy.summary);
  const { isHeartlandLive } = React.useContext(
    SessionContext
  ) as TSessionContext;

  const doUpdate = React.useMemo(
    () => debounce(updateSummary, 500),
    [updateSummary]
  );

  const handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSummary(e.target.value);
    doUpdate(e.target.value);
  };

  const totalItems = React.useMemo(() => {
    return (
      buy.total_minifig_qty +
      buy.total_cmf_qty +
      buy.total_nib_qty +
      buy.total_used_qty +
      buy.total_misc_qty
    );
  }, [buy]);

  React.useEffect(() => setSummary(buy.summary), [buy.summary]);

  const doSave = () => save();

  const doFinalize = () => {
    if (!buy.id) {
      setError('Buy must be saved first before it can be finalized');
    } else if (buy.buy_type === 'cash' && buy.cash_paid <= 0) {
      setError('Paid amount needs to more than $0');
    } else if (buy.buy_type === 'trade' && buy.credit_paid <= 0) {
      setError('Paid amount needs to more than $0');
    } else if (buy.buy_type !== 'trade' && buy.buy_type !== 'cash') {
      setError('Cash or credit needs to be selected');
    } else {
      finalize();
      setError(null);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='complete-modal'
      maxWidth='lg'
      fullWidth
      disableRestoreFocus
      scroll='paper'
    >
      <DialogTitle>Complete Transaction</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            Seller:
            <br />
            <strong>
              {buy.customer
                ? `${buy.customer.first_name} ${buy.customer.last_name}`
                : 'None Selected'}
            </strong>
            <br />
            Buyer:
            <br />
            <strong>
              {buy.creator.first_name} {buy.creator.last_name}
            </strong>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ mx: 4 }}>
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Estimated Retail Value:</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Non-Bulk:</TableCell>
                    <TableCell>
                      {formatCurrency(buy.total_retail, false)} ({totalItems}{' '}
                      items)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Bulk:</TableCell>
                    <TableCell>
                      {formatCurrency(buy.total_bulk_value, false)} (
                      {buy.total_bulk_qty} gallons)
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ fontSize: '0.8em' }}>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Credit</TableCell>
                  <TableCell>Cash</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>Offer</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    {formatCurrency(buy.credit_min, false)} -{' '}
                    {formatCurrency(buy.credit_max, false)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    {formatCurrency(buy.cash_min, false)} -{' '}
                    {formatCurrency(buy.cash_max, false)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Non-Bulk Cost</TableCell>
                  <TableCell>
                    {calcMargin(
                      buy.credit_min - buy.bulk_credit_offered,
                      buy.total_retail
                    )}{' '}
                    -{' '}
                    {calcMargin(
                      buy.credit_max - buy.bulk_credit_offered,
                      buy.total_retail
                    )}
                  </TableCell>
                  <TableCell>
                    {calcMargin(
                      buy.cash_min - buy.bulk_cash_offered,
                      buy.total_retail
                    )}{' '}
                    -{' '}
                    {calcMargin(
                      buy.cash_max - buy.bulk_cash_offered,
                      buy.total_retail
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bulk Cost:</TableCell>
                  <TableCell>
                    {calcMargin(buy.bulk_credit_offered, buy.total_bulk_value)}
                  </TableCell>
                  <TableCell>
                    {calcMargin(buy.bulk_cash_offered, buy.total_bulk_value)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          {warnings.length > 0 && (
            <Grid item xs={12}>
              <WarningDisplay warnings={warnings} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={3}>
            <CurrencyInput
              label={'Customer Asking'}
              size='medium'
              width={240}
              value={buy.asking}
              helperText={`Non-Bulk:${calcMargin(
                buy.asking -
                  (buy.buy_type === 'trade'
                    ? buy.bulk_credit_offered
                    : buy.bulk_cash_offered),
                buy.total_retail
              )} Bulk:${calcMargin(
                buy.buy_type === 'trade'
                  ? buy.bulk_credit_offered
                  : buy.bulk_cash_offered,
                buy.total_bulk_value
              )}`}
              onChange={(e) => updateAsking(parseFloat(e.target.value))}
            />
          </Grid>
          {isHeartlandLive() && (
            <Grid item xs={3}>
              <CurrencyInput
                label='Store Credit Offer'
                helperText={`Non-Bulk:${calcMargin(
                  buy.credit_offered - buy.bulk_credit_offered,
                  buy.total_retail
                )} Bulk:${calcMargin(
                  buy.bulk_credit_offered,
                  buy.total_bulk_value
                )}`}
                size='medium'
                width={240}
                value={buy.credit_offered}
                onChange={(e) =>
                  updateOfferValue('trade', parseFloat(e.target.value))
                }
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <CurrencyInput
              label='Cash Offer'
              size='medium'
              width={240}
              value={buy.cash_offered}
              helperText={`Non-Bulk:${calcMargin(
                buy.cash_offered - buy.bulk_cash_offered,
                buy.total_retail
              )} Bulk:${calcMargin(
                buy.bulk_cash_offered,
                buy.total_bulk_value
              )}`}
              onChange={(e) =>
                updateOfferValue('cash', parseFloat(e.target.value))
              }
            />
          </Grid>
          <Box sx={{ width: '100%' }} />
          <Grid item xs={3}>
            <FormControl sx={{ width: 240 }}>
              <InputLabel id='buy-type-label'>Buy Type</InputLabel>
              <Select
                labelId='buy-type-label'
                id='buy-type'
                value={buy.buy_type}
                onChange={(e: SelectChangeEvent) =>
                  updateBuyType((e.target as HTMLInputElement).value)
                }
                label='Buy Type'
              >
                <MenuItem value='NA'>-----------</MenuItem>
                {isHeartlandLive() && (
                  <MenuItem value='trade'>Store Credit</MenuItem>
                )}
                <MenuItem value='cash'>Cash</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            {buy.buy_type === 'cash' && (
              <CurrencyInput
                label='Amount Paid'
                size='medium'
                width={240}
                value={buy.cash_paid}
                helperText={`Non-Bulk:${calcMargin(
                  buy.cash_paid - buy.bulk_cash_paid,
                  buy.total_retail
                )} Bulk:${calcMargin(
                  buy.bulk_cash_paid,
                  buy.total_bulk_value
                )}`}
                onChange={(e) =>
                  updatePaidValue(buy.buy_type, parseFloat(e.target.value))
                }
              />
            )}
            {buy.buy_type === 'trade' && (
              <CurrencyInput
                label='Amount Paid'
                size='medium'
                width={240}
                value={buy.credit_paid}
                helperText={`Non-Bulk:${calcMargin(
                  buy.credit_paid - buy.bulk_credit_paid,
                  buy.total_retail
                )} Bulk:${calcMargin(
                  buy.bulk_credit_paid,
                  buy.total_bulk_value
                )}`}
                onChange={(e) =>
                  updatePaidValue(buy.buy_type, parseFloat(e.target.value))
                }
              />
            )}
          </Grid>
          {props.settings && buy.buy_type === 'cash' && (
            <>
              <Grid item xs={3}>
                <FormControl sx={{ width: 240 }}>
                  <InputLabel id='payment-type-select-label'>
                    Payment Type
                  </InputLabel>
                  <Select
                    labelId='payment-type-select-label'
                    value={buy.cash_type}
                    onChange={(e) =>
                      updateCashType(e.target.value, buy.cash_reference)
                    }
                  >
                    {props.settings.cash_types
                      .split(',')
                      .map((cashType: string) => (
                        <MenuItem key={cashType} value={cashType}>
                          {cashType}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  sx={{ ml: 2 }}
                  label='Payment Reference'
                  value={buy.cash_reference}
                  onChange={(e) =>
                    updateCashType(buy.cash_type, e.target.value)
                  }
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Summary (Customer Visible)'
              value={summary}
              multiline
              onChange={handleUpdate}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {error && <Alert severity='error'>{error}</Alert>}
        {saveStatus === api.loading && <CircularProgress />}
        <Button
          variant='contained'
          onClick={doFinalize}
          disabled={saveStatus === api.loading}
        >
          Finalize
        </Button>
        <Button
          variant='contained'
          onClick={doSave}
          disabled={saveStatus === api.loading}
        >
          Save
        </Button>
        <Button variant='contained' onClick={props.onClose}>
          Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
