import * as React from 'react';
import { Button, LinearProgress, Grid } from '@mui/material';
import { debounce } from 'lodash';

import { api } from 'lib';
import { useLegoSetsSearch } from 'api';
import { LegoSetModel } from 'model';
import { SearchThumbnail, LegoSetCard, CardModal } from 'components';

interface Props {
  query: string;
  onClick?: (legoSet: LegoSetModel) => void;
  maxResults?: number;
  size?: number;
}

export const SetSearch: React.FC<Props> = (props: Props) => {
  const { search, status, total, sets } = useLegoSetsSearch();
  const [page, setPage] = React.useState({ index: 0, total: 0 });
  const [selected, setSelected] = React.useState<LegoSetModel | null>(null);
  const handleOpen = (legoSet: LegoSetModel) => setSelected(legoSet);
  const handleClose = () => setSelected(null);
  const doSearch = React.useMemo(() => debounce(search, 500), [search]);
  const maxResults = React.useMemo(
    () => (props.maxResults ? props.maxResults : 18),
    [props.maxResults]
  );

  React.useEffect(() => {
    if (props.query.length > 3 && props.query.indexOf(',') < 0) {
      doSearch(props.query, page.index, maxResults);
    }
  }, [props.query, page, doSearch, maxResults]);

  React.useEffect(() => {
    setPage({ index: 0, total: Math.ceil(total / maxResults) });
  }, [total, maxResults]);

  const nextPage = () => {
    const newIndex = page.index + 1;
    setPage({
      index: newIndex > page.total - 1 ? page.total - 1 : newIndex,
      total: page.total,
    });
  };
  const prevPage = () => {
    const newIndex = page.index - 1;
    setPage({ index: newIndex < 0 ? 0 : newIndex, total: page.total });
  };

  if (status === api.loading) {
    return (
      <div style={{ margin: '32px 0', width: 800 }}>
        <LinearProgress />
      </div>
    );
  } else if (status === api.success) {
    return (
      <>
        <Grid container alignItems='center' spacing={1}>
          {sets &&
            sets.map((set) => (
              <Grid item xs={12} md={2} key={set.id}>
                <div onClick={() => handleOpen(set)}>
                  <SearchThumbnail
                    imageUrl={set.rebrickable_image_url}
                    imageTitle={set.name}
                    payload={set}
                    onClick={props.onClick}
                    height={props.size ? props.size : 120}
                    width={props.size ? props.size : 120}
                  >
                    {set.set_number} - {set.name}
                  </SearchThumbnail>
                </div>
              </Grid>
            ))}
          <Grid
            item
            xs={12}
            md={12}
            sx={{ textAlign: 'right', fontSize: '0.9em', marginBottom: 20 }}
          >
            <Button onClick={prevPage}>
              <strong>&lt;</strong>
            </Button>
            <Button onClick={nextPage}>
              <strong>&gt;</strong>
            </Button>
            page {page.index + 1} of {page.total} (Total {total})
          </Grid>
        </Grid>
        <CardModal open={selected !== null} onClose={handleClose}>
          <LegoSetCard legoSet={selected} />
        </CardModal>
      </>
    );
  } else {
    return <></>;
  }
};
