import * as React from 'react';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { green, red } from '@mui/material/colors';
import { formatDate } from 'lib';

export const AuditColumns: GridColDef[] = [
  {
    field: 'audit_id',
    headerName: 'Audit #',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <div>{params.row.id}</div>
      </>
    ),
  },
  {
    field: 'audit_type',
    headerName: 'Audit Type',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <div>{params.row.audit_type}</div>
      </>
    ),
  },
  {
    field: 'compliant',
    headerName: 'Passed',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {params.row.compliant ? (
            <CheckCircleIcon sx={{ color: green[500] }} />
          ) : (
            <CancelIcon sx={{ color: red[500] }} />
          )}
        </Box>
      </>
    ),
  },
  {
    field: 'addressed',
    headerName: 'Addressed',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {params.row.addressed ? (
            <CheckCircleIcon sx={{ color: green[500] }} />
          ) : (
            <CancelIcon sx={{ color: red[500] }} />
          )}
        </Box>
      </>
    ),
  },
  {
    field: 'notes',
    headerName: 'Notes',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            display: 'block',
            '&::-webkit-scrollbar': { display: 'none' },
            scrollbarWidth: 'none',
          }}
        >
          {params.row.notes}
        </Box>
      </>
    ),
  },
  {
    field: 'audit_date',
    headerName: 'Date',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <>
        <Box>{formatDate(params.row.created_at)}</Box>
      </>
    ),
  },
];
