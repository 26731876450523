import * as React from 'react';
import { Paper, Grid, Tooltip, TextField, Button, Box, Typography, Select, MenuItem, CircularProgress, SelectChangeEvent, Switch } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { InfoBarModel, InventorySettingModel } from 'model';
import { InfoBar, VendorSelector } from 'components';
import { useGetInventorySettings, useSaveInventorySettings, useGetTradeVendors } from 'api';
import { SessionContext, TSessionContext, api } from 'lib';

const defaultSettings: InventorySettingModel = {
  heartland_id: null,
  buy_trade_vendor_id: null,
  buy_trade_vendor_name: null,
  open_box_postfix: null,
  certified_set_postfix: null,
  project_set_postfix: null,
  build_only_set_postfix: null,
  complete_set_postfix: null,
  retired_set_postfix: null,
  auto_set_retired: false,
};

export const InventorySettings: React.FC = () => {
  const { currentHeartland } = React.useContext(SessionContext) as TSessionContext;
  const { getTradeVendors } = useGetTradeVendors();
  const { getInventorySettings } = useGetInventorySettings();
  const { saveInventorySettings } = useSaveInventorySettings();
  const [infoBar, setInfoBar] = React.useState<InfoBarModel | null>(null);
  const clearInfoBar = () => setInfoBar(null);
  const [settings, setSettings] = React.useState<InventorySettingModel>(defaultSettings);

  React.useEffect(() => {
    if (currentHeartland) {
      setSettings({ ...settings, heartland_id: currentHeartland.id })
      getInventorySettings.get(currentHeartland.id);
    }
  }, [currentHeartland]);

  React.useEffect(() => {
    if (getInventorySettings.status === api.success && getInventorySettings.inventorySetting) {
      setSettings(getInventorySettings.inventorySetting);
    } else if (getInventorySettings.status === api.error) {
      setInfoBar({ status: "error", message: getInventorySettings.message });
    }
  }, [getInventorySettings.status, getInventorySettings.inventorySetting]);

  const updateSetting = (key: string, value: string | number | boolean) => {
    setSettings({ ...settings, [key]: value })
  };

  const selectVendor = (event: SelectChangeEvent) => {
    if (event.target.value === "0") {
      setSettings({ ...settings, buy_trade_vendor_id: null });
    } else {
      const vendor = getTradeVendors.vendors.find((v) => `${v.id}` === event.target.value);
      setSettings({ ...settings, buy_trade_vendor_id: +event.target.value, buy_trade_vendor_name: vendor?.name });
    }
  };

  const save = () => {
    saveInventorySettings.save(settings);
  };

  React.useEffect(() => {
    if (saveInventorySettings.status === api.success && getInventorySettings.inventorySetting) {
      setInfoBar({ status: "success", message: "Settings Saved!" });
    } else if (getInventorySettings.status === api.error) {
      setInfoBar({ status: "error", message: getInventorySettings.message });
    }
  }, [saveInventorySettings.status, saveInventorySettings.inventorySetting]);

  return (
    <Box sx={{ width: "80%" }}>
      <Paper sx={{ p: 2 }}>
        <Grid container alignItems="top" spacing={1}>
          {infoBar &&
            <Grid item xs={12} md={12}>
              <InfoBar status={infoBar.status} message={infoBar.message} onClose={clearInfoBar} />
            </Grid>
          }
          <Grid item xs={9}>
            <Grid container alignItems="center" spacing={1.5}>
              <Grid item xs={12}>
                <Typography variant="h5">Heartland Inventory Settings</Typography>
                <Typography variant="body2">Inventory Settings allows you to setup how items get created/imported into Heartland from Patron.</Typography>
              </Grid>
              <Grid item xs={0.30}>
                <Tooltip title={`select Heartland vendor to use as the Vendor for all imports from Patron into Heartland`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={3.7}>Buy/Trade Vendor </Grid>
              <Grid item xs={3}>
                <VendorSelector 
                  vendorId={`${getInventorySettings.inventorySetting?.buy_trade_vendor_id}`} 
                  onSelect={(vendor)=> {
                  updateSetting('buy_trade_vendor_id', vendor.id)
                }}  
                /> 
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ p: 2, my: 1, background: '#eee', fontWeight: 700 }}>Item # Settings</Box>
              </Grid>
              <Grid item xs={0.30}>
                <Tooltip title={`The characters to add to the end of a item number's set if you are using LEGO Set numbers as your Item number, leave blank to not add anything`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={3.7}>Complete Set Postfix</Grid>
              <Grid item xs={3}>
                <TextField
                  label=""
                  fullWidth
                  value={settings.complete_set_postfix}
                  onChange={(e) => updateSetting('complete_set_postfix', e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                eg: 10282{settings.complete_set_postfix}
              </Grid>
              <Box sx={{ width: '100%' }} />
              <Grid item xs={0.30}>
                <Tooltip title={`The characters to add to the end of a item number's set if you are using LEGO Set numbers as your Item number, leave blank to not add anything`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={3.7}>Build Only Postfix</Grid>
              <Grid item xs={3}>
                <TextField
                  label=""
                  fullWidth
                  value={settings.build_only_set_postfix}
                  onChange={(e) => updateSetting('build_only_set_postfix', e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                eg: 10282{settings.build_only_set_postfix}
              </Grid>
              <Box sx={{ width: '100%' }} />
              <Grid item xs={0.30}>
                <Tooltip title={`The characters to add to the end of a item number's set if you are using LEGO Set numbers as your Item number, leave blank to not add anything`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={3.7}>Certified Set Postfix</Grid>
              <Grid item xs={3}>
                <TextField
                  label=""
                  fullWidth
                  value={settings.certified_set_postfix}
                  onChange={(e) => updateSetting('certified_set_postfix', e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                eg: 10282{settings.certified_set_postfix}
              </Grid>
              <Box sx={{ width: '100%' }} />
              <Grid item xs={0.30}>
                <Tooltip title={`The characters to add to the end of a item number's set if you are using LEGO Set numbers as your Item number, leave blank to not add anything`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={3.7}>Project Set Postfix</Grid>
              <Grid item xs={3}>
                <TextField
                  label=""
                  fullWidth
                  value={settings.project_set_postfix}
                  onChange={(e) => updateSetting('project_set_postfix', e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                eg: 10282{settings.project_set_postfix}
              </Grid>
              <Box sx={{ width: '100%' }} />
              <Grid item xs={0.30}>
                <Tooltip title={`The characters to add to the end of a item number's set if you are using LEGO Set numbers as your Item number, leave blank to not add anything`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={3.7}>Open Box Postfix</Grid>
              <Grid item xs={3}>
                <TextField
                  label=""
                  fullWidth
                  value={settings.open_box_postfix}
                  onChange={(e) => updateSetting('open_box_postfix', e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                eg: 10282{settings.open_box_postfix}
              </Grid>
              <Box sx={{ width: '100%' }} />
              <Grid item xs={0.30}>
                <Tooltip title={`The characters to add to the end of a item number's set if you are using LEGO Set numbers as your Item number, leave blank to not add anything`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={3.7}>Retired Set Postfix</Grid>
              <Grid item xs={3}>
                <TextField
                  label=""
                  fullWidth
                  value={settings.retired_set_postfix}
                  onChange={(e) => updateSetting('retired_set_postfix', e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                eg: 10282{settings.retired_set_postfix}
              </Grid>
              <Grid item xs={0.30}>
                <Tooltip title={`Turn this on to automatically add the retired postfix to the item # for retired sets`}>
                  <HelpIcon sx={{ fontSize: 14 }} />
                </Tooltip>
              </Grid>
              <Grid item xs={2}>
                <Switch
                  checked={settings.auto_set_retired}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateSetting('auto_set_retired', e.target.checked)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={2} sx={{ pl: 2 }}>
              <Grid item xs={12} md={12}>
                <Button variant="contained" fullWidth onClick={save}>Save</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box >
  );
};