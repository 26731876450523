import * as React from "react"
import {
  DialogContent,
  Grid,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material"

import { BuyModel } from "model"
import { formatCurrency, formatPercentage } from "lib";
import { StrikeThrough } from "components";
import { BuyLinesDiff } from "../edit";

interface Props {
  prevBuy: BuyModel;
  editedBuy: BuyModel;
}

export const ReviewEditSection: React.FC<Props> = ({ prevBuy, editedBuy }) => {
  const totalItems = (buy: BuyModel) => {
    return buy.total_minifig_qty + buy.total_cmf_qty + buy.total_nib_qty + buy.total_used_qty + buy.total_misc_qty;
  };

  const totalPaid = (buy: BuyModel) => {
    return buy.buy_type === "cash" ? buy.cash_paid : buy.credit_paid;
  };

  const bulkPaid = (buy: BuyModel) => {
    return buy.buy_type === "cash" ? buy.bulk_cash_paid : buy.bulk_credit_paid;
  };


  const previousOutPay = () => {
    const previousPay: number = prevBuy.buy_type === "cash" ? prevBuy.cash_paid : prevBuy.credit_paid
    const currentPay: number = editedBuy.buy_type === "cash" ? editedBuy.cash_paid : editedBuy.credit_paid
    if (previousPay !== currentPay) {
      return <StrikeThrough>(${previousPay})</StrikeThrough>
    }
  };


  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        Seller:<br /><strong>{editedBuy.customer ? `${editedBuy.customer.first_name} ${editedBuy.customer.last_name}` : "None Selected"}</strong><br />
        Buyer:<br /><strong>{editedBuy.creator.first_name} {editedBuy.creator.last_name}</strong>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ mx: 4 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Estimated Retail Value
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }}>
                  Value ($)
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }}>
                  Profit Margin
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  Non-Bulk:
                </TableCell>
                <TableCell>
                  {formatCurrency(editedBuy.total_retail, false)} ({totalItems(prevBuy)} items)
                </TableCell>
                <TableCell>
                  {formatPercentage(1 - (totalPaid(editedBuy) - bulkPaid(editedBuy)) / editedBuy.total_retail)}
                  {
                    totalPaid(prevBuy) !== totalPaid(editedBuy) &&
                    <StrikeThrough>
                      {formatPercentage(1 - (totalPaid(prevBuy) - bulkPaid(prevBuy)) / editedBuy.total_retail)}
                    </StrikeThrough>
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Bulk:
                </TableCell>
                <TableCell>
                  {formatCurrency(editedBuy.total_bulk_value, false)} ({editedBuy.total_bulk_qty} gallons)
                </TableCell>
                <TableCell>
                  {
                    bulkPaid(editedBuy) > 0 ? formatPercentage(1 - bulkPaid(editedBuy) / editedBuy.total_bulk_value) : "N/A"
                  }
                  {
                    bulkPaid(editedBuy) > 0 && bulkPaid(editedBuy) !== bulkPaid(prevBuy) &&
                    <StrikeThrough>
                      {formatPercentage(1 - bulkPaid(prevBuy) / prevBuy.total_bulk_value)}
                    </StrikeThrough>
                  }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Grid>
      <Grid item xs={4} sx={{ fontSize: "0.8em" }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>Type</TableCell>
              <TableCell>
                {editedBuy.buy_type === "trade" ? "Store Credit" : "Cash"}
                {
                  editedBuy.buy_type !== prevBuy.buy_type &&
                  <StrikeThrough>
                    ({prevBuy.buy_type === "trade" ? "Store Credit" : "Cash"})
                  </StrikeThrough>
                }
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{ fontWeight: 700 }}> {editedBuy.buy_type === "trade" ? "Credit" : "Cash"} Paid
                {
                  editedBuy.buy_type !== prevBuy.buy_type &&
                  <StrikeThrough>
                    {prevBuy.buy_type === "trade" ? "Credit" : "Cash"} Paid
                  </StrikeThrough>
                }
              </TableCell>
              <TableCell>
                ${editedBuy.buy_type === "trade" ? editedBuy.credit_paid : editedBuy.cash_paid}
                {previousOutPay()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>
                {editedBuy.buy_type === "trade" ? "Credit" : "Cash"} Offered
                {editedBuy.buy_type !== prevBuy.buy_type &&
                  <StrikeThrough>
                    {prevBuy.buy_type === "trade" ? "Credit" : "Cash"} Offered
                  </StrikeThrough>
                }
              </TableCell>
              <TableCell>${editedBuy.buy_type === "trade" ? editedBuy.credit_offered : editedBuy.cash_offered}</TableCell>
            </TableRow>
            {
              editedBuy.buy_type === "cash" && (
                <>
                  {
                    editedBuy.cash_type &&
                    <TableRow>
                      <TableCell sx={{ fontWeight: 700 }}>Payment Type</TableCell>
                      <TableCell>
                        {editedBuy.cash_type}
                        {editedBuy.cash_type !== prevBuy.cash_type && <StrikeThrough>{prevBuy.cash_type}</StrikeThrough>}
                      </TableCell>
                    </TableRow>
                  }
                  {
                    editedBuy.cash_reference &&
                    <TableRow>
                      <TableCell sx={{ fontWeight: 700 }}>Reference Info</TableCell>
                      <TableCell>
                        {editedBuy.cash_reference}
                        {editedBuy.cash_reference !== prevBuy.cash_reference && <StrikeThrough>{prevBuy.cash_reference}</StrikeThrough>}
                      </TableCell>
                    </TableRow>
                  }
                </>
              )
            }
          </TableBody>
        </Table>
      </Grid>
      {
        prevBuy.buy_type === "trade" && editedBuy.buy_type === "cash" &&
        <Grid
          item
          xs={12}
          sx={{ textAlign: "center", color: "red" }}
        >
          Changing the buy type may affect the store credit balance on the customer's account. You may need to update their balance manually.
        </Grid>
      }
      {
        totalPaid(prevBuy) !== totalPaid(editedBuy) &&
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <BuyLinesDiff
              prevBuy={prevBuy}
              editedBuy={editedBuy}
            />
          </Grid>
        </>
      }
    </Grid>
  );
}