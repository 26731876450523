import * as React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { HideOnPrint } from 'components';
import { BuyContext, TBuyContext } from '../../../buy_context';
import { AnimalBuyEntryRow } from './animal_buy_entry_row';
import { formatCurrency } from 'lib';
import { AnimalBuyLineModel } from 'model';


export const AnimalSectionInput: React.FC = () => {
  const { buy } = React.useContext(BuyContext) as TBuyContext;

  const getRows: AnimalBuyLineModel[] = React.useMemo(() => {
    return buy.animal_buy_lines.filter((row) => !row.deleted);
  }, [buy.animal_buy_lines, buy.animal_buy_lines.length]);

  return (
    <TableContainer component={Paper} sx={{ background: "#f0f0f3" }}>
      <Box sx={{ p: 2, fontWeight: 700 }}>Animals</Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="Animalures">
        <TableHead>
          <TableRow>
            <TableCell width={64}></TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Pricing</TableCell>
            <TableCell width={130}>Condition</TableCell>
            <TableCell width={80} align="center">Over</TableCell>
            <TableCell width={80} align="center">Qty</TableCell>
            <TableCell width={150}>
              <HideOnPrint>
                Value
              </HideOnPrint>
            </TableCell>
            <TableCell width={80} align="right">
              <HideOnPrint>
                Adj Value
              </HideOnPrint>
            </TableCell>
            <TableCell width={80} align="right">
              <HideOnPrint>
                Total
              </HideOnPrint>
            </TableCell>
            <TableCell width={120}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows.map((row) => (<AnimalBuyEntryRow key={row.id || row.key} row={row} />))}
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell align="right">Total</TableCell>
            <TableCell align="center">{buy.total_animal_qty}</TableCell>
            <TableCell align="right" colSpan={2}></TableCell>
            <TableCell align="right">
              <HideOnPrint>
                {formatCurrency(buy.total_animal_value)}
              </HideOnPrint>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer >
  );
};