import * as React from "react";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";

import { HideOnPrint } from 'components';
import { formatCurrency } from "lib";
import { BulkBuyLineModel } from "model";
import { BuyContext, TBuyContext } from "../../../buy_context";
import { BulkBuyEntryRow } from "./bulk_buy_entry_row";


export const BulkSectionInput: React.FC = () => {
  const { buy } = React.useContext(BuyContext) as TBuyContext;

  const getRows: BulkBuyLineModel[] = React.useMemo(() => {
    return buy.bulk_buy_lines.filter((row) => !row.deleted);
  }, [buy.bulk_buy_lines, buy.bulk_buy_lines.length]);

  return (
    <TableContainer component={Paper} sx={{ background: "#feeeda" }}>
      <Box sx={{ p: 2, fontWeight: 700 }}>Bulk</Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="NewSetetures">
        <TableHead>
          <TableRow>
            <TableCell width="400">Description</TableCell>
            <TableCell align="center">Non-LEGO</TableCell>
            <TableCell>Modifiers</TableCell>
            <TableCell align="center">Qty (gal)</TableCell>
            <TableCell width={150}>
              <HideOnPrint>
                Value
              </HideOnPrint>
            </TableCell>
            <TableCell width={80} align="right">
              <HideOnPrint>
                Adj Value
              </HideOnPrint>
            </TableCell>
            <TableCell width={80} align="right">
              <HideOnPrint>
                Total
              </HideOnPrint>
            </TableCell>
            <TableCell width={120}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows.map((row) => (<BulkBuyEntryRow key={row.id || row.key} row={row} />))}
          <TableRow>
            <TableCell colSpan={2} />
            <TableCell align="right">Total</TableCell>
            <TableCell align="center">{buy.total_bulk_qty}</TableCell>
            <TableCell align="right" colSpan={2}></TableCell>
            <TableCell align="right"><HideOnPrint>{formatCurrency(buy.total_bulk_value)}</HideOnPrint></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
