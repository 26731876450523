import * as React from "react";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material";

import { formatCurrency } from "lib";
import { BulkBuyLineModel, BuyModel } from "model";

interface Props {
  buy: BuyModel;
}

export const BulkSection: React.FC<Props> = ({ buy }: Props) => {

  const paid = React.useMemo(() => buy.buy_type === "cash" ? buy.bulk_cash_offered : buy.bulk_credit_offered, [buy]);
  const margin = React.useMemo(() => paid / buy.total_bulk_value, [buy, paid]);

  const getRows: BulkBuyLineModel[] = React.useMemo(() => {
    return buy.bulk_buy_lines.filter((row) => !row.deleted);
  }, [buy.bulk_buy_lines]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Box sx={{ p: 2, fontWeight: 700 }}>Bulk</Box>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="Bulk">
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell width={75} align="right">Qty</TableCell>
            <TableCell width={75} align="right">Cost</TableCell>
            <TableCell width={75} align="right">Value</TableCell>
            <TableCell width={75} align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getRows.map((row) => {
            return (
              <TableRow key={row.id}>
                <TableCell>{row.description}<br />{row.notes}</TableCell>
                <TableCell align="right">{row.quantity} gal</TableCell>
                <TableCell align="right">{formatCurrency(row.cost_final)}</TableCell>
                <TableCell align="right">{formatCurrency(row.value)}</TableCell>
                <TableCell align="right">{formatCurrency(row.value)}</TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell align="right"></TableCell>
            <TableCell align="right">{buy.total_bulk_qty} gal</TableCell>
            <TableCell align="right">{formatCurrency(paid)}</TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">{formatCurrency(buy.total_bulk_value)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};